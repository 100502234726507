import React, { useContext } from "react";
import { SessionContext } from "../Context/sessionContext";
import usePermisos from "../Hooks/usePermisos";

const withPermisont = (WrappedComponent, actionId) => (props) => {
  const { hasPermiso, getDisplayPermisont } = usePermisos();

  return hasPermiso(actionId) ? (
    <WrappedComponent {...props} />
  ) : (
    getDisplayPermisont(actionId)
  );
};

export default withPermisont;
