import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Th,
  TableContainer,
  Heading,
  Center,
  Spinner,
} from "@chakra-ui/react";
import axonApi from "../../Services/AxonApi";
import CuotaEditable from "./CuotaEditable";
import CampaniaEditable from "./CampaniaEditable";
import withPermisos from "../../HOC/withPermisos";


const CuotasLp = () => {
  // const [data, setData] = useState();
  const [dataCampania, setDataCampania] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isLoadingCampania, setIsLoadingCampania] = useState();

  // const getCuotasLp = () => {
  //   setIsLoading(true);
  //   axonApi.get("/internos/crm/cuotas-lp").then((res) => {
  //     if (res.status === 200) {
  //       setData(res.data);
  //       setIsLoading(false);
  //     }
  //   });
  // };

  const getCampanias = () => {
    setIsLoadingCampania(true);
    axonApi.get("/internos/crm/campania").then((res) => {
      if (res.status === 200) {
        setDataCampania(res.data);
        setIsLoadingCampania(false);
      }
    });
  };

  useEffect(() => {
    // getCuotasLp();
    getCampanias();
  }, []);

  const centerTextStyle = {
    textAlign: "center",
    color: "#4A5568",
    fontWeight: 700,
    width: 250,
  };

  return (
    <>
      {/* <Heading color="gray.600" style={{ fontSize: "24px" }} m={5} ml={0}>
        Precio de Cuotas Landing Page:
      </Heading>
      <Heading maxW={550} size="sm" color="gray.600" m={5} ml={0}>
        Aquí se puede modificar el valor de las cuotas en pesos argentinos de
        los cuatrimestres de DFOCO12M para el link LP del CRM.
      </Heading>
      {isLoading ? (
        <Center>
          <Spinner size="xl" emptyColor="gray.200" color="blue.500" />
        </Center>
      ) : (
        <TableContainer>
          <Table
            variant="striped"
            colorScheme="facebook"
            size="sm"
            maxW={600} mb={50}
            style={{ background: "white" }}
          >
            <Thead>
              <Th style={centerTextStyle}>Cuatrimestre</Th>
              <Th style={centerTextStyle}>Precio</Th>
            </Thead>
            <Tbody>
              {data?.map((item, i) => {
                return <CuotaEditable key={i} data={item} />;
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )} */}

      <Heading color="gray.600" style={{ fontSize: "24px" }} m={5} ml={0}>
        Precio de Campaña Recupero No Compró:
      </Heading>
      <Heading maxW={550} size="sm" color="gray.600" m={5} ml={0}>
        Aquí se puede modificar el valor de las cuotas en pesos argentinos de
        DFOCO12M para la campaña de los datos RecuperoNoComp
      </Heading>
      {isLoadingCampania ? (
        <Center>
          <Spinner size="xl" emptyColor="gray.200" color="blue.500" />
        </Center>
      ) : (
        <TableContainer >
          <Table
            variant="striped"
            colorScheme="facebook"
            size="sm"
            mb={15}
            maxW={600}
            style={{ background: "white" }}
          >
            <Thead>
              <Th style={centerTextStyle}>Carrera</Th>
              <Th style={centerTextStyle}>Precio</Th>
            </Thead>
            <Tbody>
              {dataCampania?.map((item, i) => {
                return <CampaniaEditable key={i} data={item} />;
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default withPermisos(CuotasLp, 230);

