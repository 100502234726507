import React, { useContext } from "react";

import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from "@chakra-ui/react";

import BuscarDato from "../BuscarDato/index";
import CargarDato from "../CargarDato/index";
import ListadoDatos from "../ListadoDatos";
import TabButton from "./TabButton";

import { DatoContext } from "../../Context/datoContext";
import { UtilDataContext } from "../../Context/utilDataContext";
import { ResultsContexttProvider } from "../../Context/restulsContext";
import { PlanCuotasContextProvider } from "../../Context/PlanCuotasContext";

import PanelAsignacion from "../PanelAsignacion";

import { ModalAxon } from "@axontraining-sistemas/lib-common";
import PlanCuotas from "../PlanCuotas";
/*
Sistema de tabs para renderizar diferentes componentes.
*/
const Tabs = () => {
  const { datos, fetchIsLoading, pushNewDato, fetchDatos, fetchPrevDatos } =
    useContext(DatoContext);

  const {
    getAllServices,
    getServiciosByGrupo,
    estadosVenta,
    setOperadoresBySupervisor,
    operadores,
    paises,
    posgrados,
    carreras,
    ebooks,
    origenesDato,
  } = useContext(UtilDataContext);

  return (
    <>
      <ChakraTabs variant="enclosed" colorScheme="blue">
        <TabList __css={{ borderBottom: "5px solid #1C6EA4" }}>
          <TabButton title="Cargar Dato al CRM" />
          <TabButton title="Buscar Dato" />
        </TabList>

        {/* Divider */}
        <Box height="5px" bg="blue.500" mt={0.5} />

        <TabPanels bg="#ECF2F7" shadow="lg" borderRadius="lg" borderTop="none">
          <TabPanel>
            <CargarDato
              {...{ paises, getAllServices, pushNewDato, origenesDato }}
            />
          </TabPanel>
          <TabPanel>
            <BuscarDato
              {...{
                fetchDatos,
                fetchIsLoading,
                paises,
                estadosVenta,
                getServiciosByGrupo,
                setOperadoresBySupervisor,
                operadores,
                posgrados,
                carreras,
                ebooks,
                fetchPrevDatos,
              }}
            />
          </TabPanel>
        </TabPanels>
      </ChakraTabs>

      <ResultsContexttProvider>
        <PanelAsignacion datos={datos} />
        <ListadoDatos datos={datos} fetchIsLoading={fetchIsLoading} />
      </ResultsContexttProvider>
    </>
  );
};

export default Tabs;
