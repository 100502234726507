import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  HStack,
  Heading,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

const DrawerManager = ({ trigger, size, title, component }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Button
        ref={btnRef}
        colorScheme="white.100"
        onClick={onOpen}
        p="0 !important"
        margin="0 !important"
      >
        {trigger}
      </Button>
      <Drawer
        isOpen={isOpen}
        size={size ? size : "sm"}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack
              spacing="10px"
              pb="5px"
              borderBottomWidth="4px"
              borderColor="blue.500"
              margin="0"
            >
              <Heading size="md" color="blue.500">
                {title}
              </Heading>
            </HStack>
          </DrawerHeader>

          <DrawerBody>{component}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerManager;
