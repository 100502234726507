export const parseArrayToReactSelect = (datos, value, label) => {
  const options = datos.map((dato) => {
    return { label: dato[label], value: dato[value] };
  });
  return options;
};

export const addQueryParamsToUrl = (url, values) => {
  const entries = Object.entries(values);

  entries.forEach((entrie) => {
    if (url.indexOf("?") === -1) {
      url = url + "?" + entrie[0] + "=" + entrie[1];
    } else {
      url = url + "&" + entrie[0] + "=" + entrie[1];
    }
  });

  return url;
};

export const getFileExtension = (fullFileName) =>
  fullFileName?.split(".")?.pop();

export const parseDataLink = (data, typeLink = null, habilitaVentaLaboral = 0) => {
  data.usuarioResponsable = data.operador;
  delete data.operador;
  delete data.segundaEtapa;
  delete data.sinEmail;

  data.idServicio = parseInt(data.idServicio);
  data.idAula = parseInt(data.idAula);
  data.idPais = parseInt(data.idPais);

  if (
    typeLink === "compra" &&
    (data.idServicio === 20 || data.idServicio === 86) &&
    !data.idAula
  )
    //data.idAula = 17;
    data.idAula = 19;

  if (
    typeLink === "seña" ||
    ((data.idServicio === 20 || data.idServicio === 86) && !data.idAula)
  )
    //data.idAula = data.idServicio === 86 && habilitaVentaLaboral == 1 ? 5 : 17;
    data.idAula = 19;

  return data;
};
