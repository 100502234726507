import React, { useState, useContext, useEffect } from "react";

import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";

import { Box, Flex, Button, SimpleGrid, Heading, Tag } from "@chakra-ui/react";

import { DatoContext } from "../../Context/datoContext";

import { ResultsContext } from "../../Context/restulsContext";
import { UtilDataContext } from "../../Context/utilDataContext";
import { clearData } from "../../Services/UltilsService";
import { useToast } from "@chakra-ui/toast";
import { postAsignacionMultiple } from "../../Services/CargaDatoService";
import withPermisos from "../../HOC/withPermisos";
import { SEARCH_ADMIN } from "../../Constants/buscarDato";
const PanelAsignacion = React.memo(({ datos }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { fetchPrevDatos } = useContext(DatoContext);

  const { checkedRows, dispatchCheck, counter } = useContext(ResultsContext);
  const { estadosVenta, operadores, lineas } = useContext(UtilDataContext);
  const [showShadow, setShowShadow] = useState(false);

  const toast = useToast();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const panelRef = document.getElementsByClassName("ref-panel")[0];
      if (!panelRef) return;

      if (window.scrollY >= panelRef.offsetTop) setShowShadow(true);
      else setShowShadow(false);
    });
  }, []);

  const initialValues = {
    operador: "",
    idEstadoVenta: "",
    idLinea: "",
  };

  const marcarTodo = () => {
    let arrayVentas = [];
    let fechas = datos.fechas;
    let i = 0;

    for (let fecha of fechas) {
      datos.data[fecha].rows.map((row) => {
        arrayVentas.push(row.idVenta);
        i++;
      });
    }

    dispatchCheck({ action: "checkAll", data: arrayVentas });
  };

  const handleChange = (values) => {};

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const data = clearData(values);

    const dataFormateada = {
      idVenta: checkedRows,
      data: data,
    };

    try {
      const res = await postAsignacionMultiple(dataFormateada);

      await fetchPrevDatos();

      toast({
        title: "Datos editados con exito!",
        description: res.data,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      await dispatchCheck({ action: "unCheckAll" });
    } catch (e) {
      toast({
        title: "Error al editar el dato!",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  if (checkedRows.length == 0) return null;

  return (
    <Box
      className="ref-panel"
      position="sticky"
      top="50px"
      zIndex="2"
      borderRadius="lg"
      borderBottomWidth={"4px"}
      borderColor={"blue.500"}
      bgColor="white"
      boxShadow={`${showShadow && "dark-lg"}`}
      paddingBottom="10px"
      mt="5vh !important"
    >
      <Flex justify="space-between">
        <Heading color="gray.600" size="h3" p={5} pb={2}>
          Asignacion Multiple (vista del supervisor)
        </Heading>

        <Tag
          mt={5}
          mr={2}
          size="sm"
          color="gray.500"
          colorScheme="blue"
          borderRadius="full"
        >
          Seleccionados: {counter}
        </Tag>
      </Flex>
      <Flex p={4}>
        <Flex w="75%" justifyContent="start">
          <FormContainer
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            initialValues={initialValues}
          >
            <SimpleGrid columns={4} spacing={3}>
              <FormField
                name="responsable"
                label="Operador"
                placeholder="Seleccione"
                type="react-select"
                options={operadores}
                labelColor="gray.500"
              />
              <FormField
                name="idEstadoVenta"
                label="Estado venta"
                placeholder="Seleccione"
                type="react-select"
                options={estadosVenta}
                labelColor="gray.500"
              />
              <FormField
                name="idLinea"
                label="Linea"
                placeholder="Seleccione"
                type="react-select"
                options={lineas}
                labelColor="gray.500"
              />
              <Flex alignItems="end">
                <Box ml="8">
                  <FormField
                    name="submit"
                    type="submit"
                    placeholder="Asignar"
                    bgInput="orange.500"
                    colorScheme="orange.500"
                    labelColor="gray.500"
                    size="md"
                    isLoading={isLoading}
                  />
                </Box>
              </Flex>
            </SimpleGrid>
          </FormContainer>
        </Flex>

        <Flex w="25%" justifyContent="end" alignItems="end">
          <Button
            onClick={() => marcarTodo()}
            colorScheme="blue"
            variant="outline"
            m={1}
            size="sm"
          >
            Marcar todo
          </Button>
          <Button
            onClick={() => dispatchCheck({ action: "unCheckAll" })}
            colorScheme="blue"
            m={1}
            size="sm"
            variant="outline"
          >
            Desmarcar todo
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
});

export default withPermisos(PanelAsignacion, SEARCH_ADMIN);
