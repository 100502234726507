import { useState } from "react";
import { getReporteCascada } from "../Services/ReporteCascadaService";
import { firstDayOfTheMonth, today } from "../Services/UltilsService";
import moment from "moment";
import "moment/locale/es";

const useCascada = () => {
  const [loading, setLoading] = useState(false);
  const [reportes, setReportes] = useState([]);
  const [paisDisabled, setPaisDisabled] = useState(true);
  const [pais, setPais] = useState("");
  const [formValues, setFormValues] = useState({
    desde: firstDayOfTheMonth,
    hasta: today,
  });

  const getReporte = async (formValues) => {
    setLoading(true);
    const paisObject = { idPais: pais };
    try {
      const data = await getReporteCascada(formValues, paisObject);
      setReportes(data.data);
    } catch (error) {
      setReportes([]);
      console.log(error);
    }
    setLoading(false);
  };

  const formatNumber = (total) => {
    return new Intl.NumberFormat("es-ES").format(total);
  };

  const formatParaCobrar = (paraCobrar) => {
    const values = Object.values(paraCobrar);
    const keys = Object.keys(paraCobrar).map((element, key) => ({
      name: element.replace("_", " "),
      value: !values[key] ? 0 : formatNumber(values[key]),
    }));
    return keys;
  };

  const getFechaFormateada = (fecha) => {
    const date = moment(fecha).format("MMM YYYY");

    return date.charAt(0).toUpperCase() + date.slice(1);
  };

  const getMesAnterior = (fecha) => {
    const date = moment(fecha).subtract(1, "month").format("MMM YYYY");
    return date.charAt(0).toUpperCase() + date.slice(1);
  };

  const disableSelectPaises = () => {
    setPaisDisabled(formValues.producto !== "carrera");
  };

  const handleChange = ({ values }) => {
    const idPais =
      values.producto === "posgrados"
        ? ""
        : values.producto === "argentina"
        ? 1
        : 999;
    setFormValues(values);
    if (values.producto) {
      setPais(idPais);
    }
  };

  const handleSubmit = (values) => {
    getReporte(values);
  };

  return {
    loading,
    reportes,
    paisDisabled,
    setFormValues,
    getReporte,
    formatParaCobrar,
    getFechaFormateada,
    getMesAnterior,
    disableSelectPaises,
    handleChange,
    handleSubmit,
    formatNumber,
  };
};

export default useCascada;
