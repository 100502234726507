import React, { useContext } from "react";
import { SessionContext } from "../Context/sessionContext";

const withPermisos = (WrappedComponent, actionId) => (props) => {
  const { permisos } = useContext(SessionContext);

  switch (!!permisos[actionId]) {
    case true:
      return <WrappedComponent {...props} />;
    case false:
      return null;
    default:
      return null;
  }
};

export default withPermisos;
