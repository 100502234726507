import axonApi from "./AxonApi";
import { addQueryParamsToUrl } from "./FormService";
import { clearData } from "./UltilsService";
export const getReporte = async (queryParams) => {
  const url = "/internos/cobranzas/reportes" + queryParams;

  const { data } = await axonApi.get(url);

  return data;
};

export const fetchReporteVentas = async (params) => {
  params = clearData(params);
  const url = addQueryParamsToUrl("/internos/crm/ventas/reportes", params);

  try {
    const res = await axonApi.get(url);
    return res.data;
  } catch (e) {
    throw e.response.data.error;
  }
};
