import React, { useContext, useReducer, useState } from "react";

import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { HStack, SimpleGrid, Box } from "@chakra-ui/react";

import {
    firstDayOfTheMonth,
    mainMargin,
    today,
} from "../../../Services/UltilsService";
import validation from "../validation";

import { useToast } from "@chakra-ui/toast";

import { getAsignaciones } from "../../../Services/AsignacionesService";

const FiltroAsignaciones = ({ setAsignaciones }) => {
  const [formValues, setFormValues] = useState({
    desde: firstDayOfTheMonth,
    hasta: today
  });
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const data = await getAsignaciones(formValues.desde, formValues.hasta);
      setAsignaciones(data.data)
    } catch (error) {
      setAsignaciones([])
      toast({
        title: "No se encontraron asignaciones",
        description: error.response.data.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false)
  };

  const handleChange = ({ values }) => setFormValues(values);

  return (
    <div>
      <FormContainer
        handleSubmit={handleSubmit}
        validationSchema={validation}
        initialValues={{
          desde: firstDayOfTheMonth,
          hasta: today,
          idGrupo: 1,
        }}
        handleChange={handleChange}
      >
        <SimpleGrid columns={[1, 2, 5]} spacing={mainMargin}>

          <FormField
            bgInput="white"
            name="desde"
            label="Fecha desde"
            placeholder="Seleccione desde"
            type="date"
            labelColor="gray.500"
          />

          <FormField
            bgInput="white"
            name="hasta"
            label="Fecha hasta"
            placeholder="Seleccione hasta"
            type="date"
            labelColor="gray.500"
          />
        </SimpleGrid>

        <HStack mt={mainMargin} spacing={5}>
          <Box>
            <FormField
              name="submit"
              type="submit"
              placeholder="Buscar"
              bgInput="orange.500"
              colorScheme="orange.500"
              labelColor="gray.500"
              size="sm"
              isLoading={isLoading}
            />
          </Box>
        </HStack>
      </FormContainer>
    </div>
  );
};

export default FiltroAsignaciones;
