import React from "react";

const useSectoresVenta = () => {
  //
  /*
    PARA EL FUTURO
    Esto lo dejamos por si algun dia necesitamos sectorizar el select de paises
    Deberiamos encapsular aca todo lo referido a la division del sector de ventas, toda esa logica falopa.
 */

  /*Recibe un array de select parseados a react select  [{label, value},...] */
  const parsearPaisesPorSector = (paises) => {
    //

    if (paises.length === 0) return [];

    const isExterior = hasPermiso(permisoSectores.SECTOR_EXTERIOR);

    const isArgentina = hasPermiso(permisoSectores.SECTOR_ARGENTINA);

    let paisesCopy = [...paises];

    const argIndex = paisesCopy.findIndex((pais) => parseInt(pais.value) === 1);

    if (isExterior) paisesCopy.splice(argIndex, 1);

    if (isArgentina) paisesCopy = [paisesCopy[argIndex]];

    return paisesCopy;
  };

  const addSelectAllExterior = (paises) => [
    ...paises,
    { label: "Todos Exterior", value: 999 },
  ];

  return { parsearPaisesPorSector, addSelectAllExterior };
};

export default useSectoresVenta;
