import { useContext } from "react"
import {
  Button,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { CascadaContext } from "../../../Context/cascadaContext";

const ModalBajasMes = ({ onClose, reporte }) => {
  const { getFechaFormateada } = useContext(CascadaContext);

  return (
    <ModalContent>
      <ModalHeader>
        Bajas de {getFechaFormateada(reporte.fecha)}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <p><b>Total Cuotas en Cartera</b>: {reporte.detalleBajas.totalCartera}</p>
        <p><b>Cantidad de Bajas</b>: {reporte.detalleBajas.totalBajas}</p>

        <p><b>Porcentaje de Bajas</b>: {((reporte.detalleBajas.totalBajas * 100) / reporte.detalleBajas.totalCartera).toFixed(2)}%</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cerrar</Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default ModalBajasMes