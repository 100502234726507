import React, { useState, useEffect } from "react";
import { Heading, Spinner } from "@chakra-ui/react";
import WhatsappTable from "./WhatsappTable";
import axonApi from "../../Services/AxonApi";
import { Center } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import usePermisos from "../../Hooks/usePermisos";

const WhatsappCarrousel = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState();
  const toast = useToast();
  const { hasPermiso } = usePermisos();

  const getUsers = async () => {
    await axonApi.get(`/internos/crm/asesores`).then((res) => {
      if (res.data.status === "Error") {
        toast({
          title: "Error!",
          description: res.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setUsersData(res.data.data);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, []);

  if (!hasPermiso(433)) {
      window.location.href = "/404";
    };

  return (
    <>
      <Heading color="gray.600" m={5} ml={0}>
        Whatsapp Carrousel
      </Heading>
      {isLoading ? (
        <Center>
          <Spinner size="xl" emptyColor="gray.200" color="blue.500" />
        </Center>
      ) : (
        <WhatsappTable usersData={usersData} getUsers={getUsers} />
      )}
    </>
  );
};

export default WhatsappCarrousel;
