import React, { useState, useEffect, useContext, useReducer } from "react";

import { clearData } from "../Services/UltilsService";
import { addQueryParamsToUrl } from "../Services/FormService";
import axonApi from "../Services/AxonApi";
import { fechDatoByIdVenta } from "../Services/BuscarDatoService";

import { SEARCH_ADMIN } from "../Constants/buscarDato";
import usePermisos from "./usePermisos";
import { SessionContext } from "../Context/sessionContext";
const useBuscarDato = () => {
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [datos, setDatos] = useState();
  const [prevSearch, setPrevSearch] = useState("");

  const {
    session: { login },
  } = useContext(SessionContext);

  const { hasPermiso } = usePermisos();

  const pushNewDato = async (idVenta, fechaRow, isCargaDato = false) => {
    if (!idVenta) return false;

    let operador;

    const newData = { ...datos };

    // Elimino el objeto anterior.
    if (newData.data[fechaRow]) {
      const filteredRow = newData.data[fechaRow]?.rows.filter(
        (row) => row.idVenta != idVenta
      );

      newData.data[fechaRow].rows = filteredRow;
    }

    const isAdmin = hasPermiso(SEARCH_ADMIN);
    if (!isAdmin) operador = login;

    // Busco el nuevo dato y lo seteo.
    const data = await fechDatoByIdVenta(idVenta, operador);
    const fecha = data.fechas[0];

    if (data.fechas.length > 0) {
      const { fechas } = datos;

      const existeFecha = fechas.includes(fecha);

      if (existeFecha) {
        const value = data.data[fecha].rows[0];
        newData.data[fecha].rows.unshift(value);
      } else {
        fechas.unshift(fecha);
        newData.data[fecha] = data.data[fecha];
      }
    }

    if (isCargaDato && newData.data[fecha]) {
      newData.data[fecha]["totalDatos"] = datos.data[fecha].totalDatos
        ? datos.data[fecha].totalDatos + 1
        : 1;
    }

    setDatos(newData);
  };

  const hideIcon = async (idVenta, fechaCarga) => {
    const newData = { ...datos };
    if (newData.data[fechaCarga]) {
      newData.data[fechaCarga]?.rows.map((row) => {
        if (row.idVenta == idVenta) {
          row.ventaCarrera = 0;
          return row;
        }
        return row;
      });
    }
    setDatos(newData);
  };

  const fetchPrevDatos = async () => {
    try {
      const { data } = await axonApi.get(prevSearch);
      setDatos(data);
    } catch (error) {
      return [];
    }
  };

  const fetchDatos = async (objectForm) => {
    setFetchIsLoading(true);
    // dispatchCheck({ action: "unCheckAll" });
    objectForm = clearData(objectForm);

    if (
      objectForm.nombre ||
      objectForm.apellido ||
      objectForm.celular ||
      objectForm.email
    ) {
      delete objectForm.operador;
      delete objectForm.desde;
      delete objectForm.hasta;
    }

    const urlWithParams = addQueryParamsToUrl(
      "/internos/crm/buscador/dato",
      objectForm
    );

    setPrevSearch(urlWithParams);

    try {
      const { data } = await axonApi.get(urlWithParams);
      setFetchIsLoading(false);
      setDatos(data);
    } catch (error) {
      setFetchIsLoading(false);
      return [];
    }
  };

  return {
    fetchDatos,
    fetchIsLoading,
    datos,
    pushNewDato,
    fetchPrevDatos,
    hideIcon,
  };
};

export default useBuscarDato;
