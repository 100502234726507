import React from "react";
import { Flex, FormLabel, Input, Center, Tooltip } from "@chakra-ui/react";

const FormControlTrambolico = ({
  name,
  label,
  value,
  edit,
  handleChange,
  tooltipMsg,
}) => {
  return (
    <Flex borderWidth="2px" borderColor="blue.500" borderRadius="lg">
      <Flex
        w="70%"
        justifyContent="center"
        alignItems="center"
        borderRight="2px"
        borderColor="blue.500"
      >
        <Tooltip label={tooltipMsg} isDisabled={!tooltipMsg}>
          <FormLabel
            m={0}
            color="blue.500"
            _hover={tooltipMsg && { cursor: "pointer" }}
          >
            {label}
          </FormLabel>
        </Tooltip>
      </Flex>

      <Flex
        w="30%"
        justifyContent="center"
        backgroundColor="white"
        borderRadius="lg"
      >
        <Input
          value={value}
          onChange={(e) => handleChange(e)}
          name={name}
          w="40%"
          backgroundColor="whiteAlpha.100"
          isReadOnly={!edit}
          variant="unstyled"
          border={0}
        />
      </Flex>
    </Flex>
  );
};

export default FormControlTrambolico;
