import React, { createContext } from "react";
import usePlanDeCuotas from "../Hooks/usePlanDeCuotas";

export const PlanCuotasContext = createContext();

export const PlanCuotasContextProvider = (props) => {
  const datos = usePlanDeCuotas(props.idVenta, props.pais);

  return (
    <PlanCuotasContext.Provider value={datos}>
      {props.children}
    </PlanCuotasContext.Provider>
  );
};
