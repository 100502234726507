import React, { useState, useEffect } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import useAxonForm from "../../Hooks/useAxonForm";
import validation from "./validation";
import axonApi from "../../Services/AxonApi";
import { setArrayForSelect } from "../../Services/LotesServices";
import { useToast } from "@chakra-ui/toast";

const AgregarAsesor = (props) => {
  const [isLoading, setIsLoading] = useAxonForm();
  const [formValues, setFormValues] = useState({});
  const [grupoOptions, setGruposOptions] = useState([]);
  const toast = useToast();
  
  const {
    userInfo,
    gruposAsesores,
    sectores,
    sectorSelected,
    grupoSelected,
    editar,
    idAsesor,
    getUsers
  } = props;

  const asesorData = {
    login: formValues.login,
    whatsapp: formValues.whatsapp,
    idGrupo: formValues.grupo,
    idSector: formValues.sector,
    tope: formValues.asignadosTopeDiario,
    activo: formValues.activo,
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    axonApi.post(`/internos/crm/asesores`, asesorData).then((res) => {
      if (res.data.status !== "Error") {
        setIsLoading(false);
        getUsers()
        props.onClose();
        toast({
          title: "Asesor agregado!",
          description: "El asesor se agregó correctamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error al agregar el asesor!",
          description: `${res.data.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    });
  };

  const handleSubmitEdit = () => {
    setIsLoading(true);

    axonApi
      .put(`/internos/crm/asesores/${idAsesor}`, asesorData)
      .then((res) => {
        if (res.data.status !== "Error") {
          setIsLoading(false);
          getUsers()
          props.onClose();
          toast({
            title: "Asesor editado!",
            description: "El asesor se editó correctamente.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error al editar el asesor!",
            description: `${res.data.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    setGruposOptions(setArrayForSelect(gruposAsesores, "id", "grupo"));
  }, []);

  const handleChange = ({ values }) => {
    setFormValues(values);
  };

  const activoOptions = [
    {
      value: 1,
      label: "Si",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  
  const sectorOptions = [
    {
      value: 3,
      label: "Ventas Argentina",
    },
    {
      value: 4,
      label: "Posgrado",
    },
    {
      value: 21,
      label: "Ventas Exterior",
    },
    {
      value: 30,
      label: "Posgrado Exterior",
    },
  ];

  const initialValues = {
    login: userInfo?.login,
    nombre: userInfo?.nombre,
    whatsapp: userInfo?.whatsapp,
    grupo: grupoSelected,
    sector: sectorSelected,
    asignadosTopeDiario: userInfo?.asignadosTopeDiario,
    activo: userInfo?.activo,
  };

  return (
    <FormContainer
      handleSubmit={editar ? handleSubmitEdit : handleSubmit}
      initialValues={initialValues}
      handleChange={handleChange}
      validationSchema={validation}
    >
      <SimpleGrid columns={2} spacing={2}>
        <FormField
          name="login"
          label="Login"
          placeholder="Ingrese login"
          labelColor="gray.500"
          bgInput="white"
          type="input"
        />
        <FormField
          name="whatsapp"
          label="Celular (Colocar el prefijo, ej: 549)"
          placeholder="Ingrese celular"
          labelColor="gray.500"
          bgInput="white"
          type="input"
        />
        <FormField
          name="grupo"
          label="Grupo"
          placeholder="Seleccione grupo"
          type="react-select"
          labelColor="gray.500"
          options={grupoOptions}
        />
        <FormField
          name="sector"
          label="Sector"
          placeholder="Seleccione sector"
          type="react-select"
          labelColor="gray.500"
          options={sectorOptions}
        />
        <FormField
          name="asignadosTopeDiario"
          label="Tope Diario"
          placeholder="Ingrese tope diario"
          labelColor="gray.500"
          bgInput="white"
          type="input"
        />
        <FormField
          name="activo"
          label="Activo"
          placeholder="Seleccione activo"
          type="react-select"
          labelColor="gray.500"
          options={activoOptions}
        />
      </SimpleGrid>
      <FormField
        name="submit"
        placeholder={"Guardar"}
        type="submit"
        colorScheme={"blue"}
        isLoading={isLoading}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      />
    </FormContainer>
  );
};

export default AgregarAsesor;
