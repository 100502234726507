import React, { useContext } from "react";

import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from "@chakra-ui/react";

import FiltroReporte from "./FiltroReporte";

import TabButton from "../Tabs/TabButton";
import { UtilDataContextProvider } from "../../Context/utilDataContext";
import Results from "./Results";
import Totales from "./Totales";
import ButtonGroup from "./ButtonGruop";
const Reporte = () => {
  //
  const endOfPageRef = React.useRef(null);
  const topOfPageRef = React.useRef(null);
  //

  /*
     El filtro sera fecha desde, fecha hasta y el operador que sera siempre uno mismo, al menos que tenga el permiso 383. En ese caso deberia traer los operadores de  origen_ventas (ya hay un endpoint para esto).
  */

  return (
    <div ref={topOfPageRef}>
      <ChakraTabs variant="enclosed" colorScheme="blue">
        <TabList __css={{ borderBottom: "5px solid #1C6EA4" }}>
          <TabButton title="Listado ventas" />
        </TabList>
        <Box height="5px" bg="blue.500" mt={0.5} />

        <TabPanels>
          <TabPanel bg="#ECF2F7" shadow="lg" borderRadius="lg" borderTop="none">
            <UtilDataContextProvider>
              <FiltroReporte />
            </UtilDataContextProvider>
          </TabPanel>
        </TabPanels>
      </ChakraTabs>

      <ButtonGroup ref={endOfPageRef} />
      <Results />
      <Totales ref={{ endOfPageRef, topOfPageRef }} />
    </div>
  );
};

export default Reporte;
