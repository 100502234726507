import FormularioCompra from "../Components/GeneracionLink/Compra/FormularioCompra";
import FormularioSena from "../Components/GeneracionLink/Sena/FormularioSena";
import FormularioLinkCarrera from "../Components/GeneracionLink/LinkCarrera/FormularioLinkCarrera";

export const tabsLinks = [
  {
    name: "Carrera/Posgrado/Producto",
    component: FormularioCompra,
  },

  {
    name: "Seña",
    component: FormularioSena,
  },

  {
    name: "Link LP",
    component: FormularioLinkCarrera,
  },
];
