export const data = [
  {
    texto: "Cobrar por LAPOS",
    link: "https://vnet.visa.com.ar/cspv/adm/GetLogout.event",
    target: "_blank",
  },
  {
    texto: "Cobrar por LAPOS",
    link: "https://prismanet.prismamediosdepago.com/cspv/adm/GetLogout.even",
    target: "_blank",
  },
  {
    texto: "Cobrar por POSNET",
    link: "https://www1.posnet.com.ar/webpos-frontend",
    target: "_blank",
  },
  {
    texto: "Buscar IP",
    link: "http://whatismyipaddress.com/ip-lookup",
    target: "_blank",
  },
  {
    texto: "Buscar Datos.com",
    link: "http://www.buscardatos.com/Personas/Apellido/",
    target: "_blank",
  },
  {
    texto: "Buscar CUIL en Arg",
    link: "https://www.dateas.com/es/consulta_cuit_cuil",
    target: "_blank",
  },
  {
    texto: "Buscar CUIT en Arg",
    link: "https://www.cuitonline.com/",
    target: "_blank",
  },
  {
    texto: "Prefijos de Argentina",
    link: "http://ar.portaltelefonico.com/",
    target: "_blank",
  },
  {
    texto: "Acceso a Clarity",
    link: "https://app01.clarity.com.ar/app/Login_Unificado.asp",
    target: "_blank",
  },
  {
    texto: "Datos Bancarios para Transferir en Argentina",
    link: "https://www.axonintranet.com.ar/internos/util/cbu_icbc.php",
    target: "_blank",
  },
  {
    texto: "QuickSight",
    link: "https://us-east-1.signin.aws.amazon.com/oauth?SignatureVersion=4&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIYOJP4OBNT7XFVSQ&X-Amz-Date=2020-05-30T14%3A08%3A53.857Z&X-Amz-Signature=84f82c95cdfcbe40016c4b41d067d053a373986ca83593b567ef2a7d29775ace&X-Amz-SignedHeaders=host&client_id=arn%3Aaws%3Aiam%3A%3A015428540659%3Auser%2Fspaceneedle-prod&redirect_uri=https%3A%2F%2Fquicksight.aws.amazon.com%2Fsn%2Fstart%3Fdirectory_alias%3Dprueba-quick%26ref_%3Dpe_3035110_233161080%26state%3DhashArgs%2523%26isauthcode%3Dtrue&ref_=pe_3035110_233161080&response_type=code&state=hashArgs%23&directory_alias=prueba-quick&rdfs=true",
    target: "_blank",
  },
  {
    texto: "Correo Asesores de Venta",
    link: "https://axont.awsapps.com/mail",
    target: "_blank",
  },
  {
    texto: "Menú para Profesores",
    link: "https://shade-kryptops-653.notion.site/Profesores-Informaci-n-general-21eaaf30f62e427583e1f50a4afe4423",
    target: "_blank",
  },
];
