import axonApi from "../Services/AxonApi";

export const fechDatoByIdVenta = async (idVenta, operador = null) => {
  let queryParam = operador ? `&operador=${operador}` : "";
  const { data } = await axonApi.get(
    `/internos/crm/buscador/dato?idVenta=${idVenta}${queryParam}`
  );

  return data;
};

export const fetchServiciosByGrupo = async (grupo = 1) => {
  try {
    const { data } = await axonApi.get(`/academico/grupos/${grupo}/servicios`);

    if (!data.length || data.length <= 0) return [];

    return data;
  } catch (erro) {
    return [];
  }
};

export const fetchEstadosVenta = async () => {
  try {
    const { data } = await axonApi.get("/internos/crm/ventas/estados");

    return data;
  } catch (e) {
    return [];
  }
};

export const fetchLineas = async () => {
  try {
    const { data } = await axonApi.get("/internos/crm/ventas/lineas");

    return data;
  } catch (e) {
    return [];
  }
};

export const fetchOperadoresSupervisor = async (login) => {
  try {
    const { data } = await axonApi.get(
      `/internos/crm/ventas/operadores?login=${login}`
    );

    return data;
  } catch (e) {
    return [];
  }
};

export const fetchOperadoresBySector = async (idSector) => {
  try {
    const res = await axonApi.get(`/usuarios?idSector=${idSector}`);

    return res.data;
  } catch (error) {
    return [];
  }
};

export const fetchOrigenesDato = async () => {
  try {
    const res = await axonApi.get("/ventas/origenes");
    return res.data;
  } catch (error) {
    return [];
  }
};

export const fetchGrupos = async () => {
  try {
    const res = await axonApi.get("/internos/crm/grupos");
    return res.data;
  } catch (error) {
    return [];
  }
};
