import React from "react";
import { ReporteContextProvider } from "../../Context/reporteContext";
import Reporte from "./Reporte";

const Index = () => {
  return (
    <>
      <ReporteContextProvider>
        <Reporte />
      </ReporteContextProvider>
    </>
  );
};

export default Index;
