import React from "react";

import { Box, Tooltip, Center } from "@chakra-ui/react";

const RowPais = ({ urlImage, nombrePais }) => {
  const altImg =
    "https://axon-campus.s3.us-east-2.amazonaws.com/otros/angry-boui.png";

  return (
    <Tooltip label={nombrePais}>
      <Center>
        <Box w="20px" borderRadius="50%">
          <img alt="bandera pais" src={urlImage || altImg} />
        </Box>
      </Center>
    </Tooltip>
  );
};

export default RowPais;
