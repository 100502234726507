import axonApi from "./AxonApi";

export const putDato = async (data, idVenta, fechaToday = null) => {
  /* El parámetro fechaToday se envía en caso de que al actualizar el dato se quiera actualizar tareas->fecha */
  if (fechaToday) data.fecha = fechaToday;

  data.idEstadoVenta = data.estadoVenta;
  delete data.estadoVenta;

  const url = `/internos/crm/ventas/${idVenta}/dato`;

  try {
    const res = await axonApi.put(url, data);
    return res;
  } catch (e) {
    throw e.response.data.error;
  }
};

export const validateEmail = async (data, idVenta, updateEmail = false) => {
  let url = `internos/crm/ventas/${idVenta}/dato/email`;
  if (updateEmail) url += "?editar=1";
  const newData = { newEmail: data.email, idServicio: data.idServicio };
  try {
    const { data } = await axonApi.put(url, newData);
  } catch (error) {
    throw error.response.data;
  }
};
