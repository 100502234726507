import React, { useState, useContext } from "react";
import { SimpleGrid, Flex } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";

import BotonesEditables from "../../Common/BotonesEdicion/BotonesEditables";
import FormControlTrambolico from "./FormControlTrambolico";

import { DatoContext } from "../../Context/datoContext";
import {
  postCargaDatoDiario,
  updateStateContext,
} from "../../Services/CargaDatoService";
import { setInitialValues } from "./setInitialValues";

const CargaDatosDiaria = ({ fecha }) => {
  const [edit, setEdit] = useState(false);
  const { datos, setDatos } = useContext(DatoContext);

  const initialValues = setInitialValues(fecha, datos);
  const toast = useToast();

  const [data, setData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const clearChanges = (e) => {
    setData(initialValues);
  };

  const handleSubmit = async () => {
    try {
      const res = await postCargaDatoDiario(data);

      updateStateContext(data, setDatos, datos, fecha);

      toast({
        title: "Dato cargado!",
        description: "Dato cargado con exito!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "Error al cargar dato!",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex justifyContent="end">
      <Flex w="80%">
        <SimpleGrid columns={4} spacing={5}>
          <FormControlTrambolico
            edit={edit}
            label="Entrantes:"
            name="entrantes"
            value={data.entrantes ?? 0}
            handleChange={handleChange}
            tooltipMsg="Cargar los codigos + las respuestas a primera hora del dia"
          />
          <FormControlTrambolico
            edit={edit}
            label="Alumnos:"
            name="alumnos"
            value={data.alumnos ?? 0}
            handleChange={handleChange}
            tooltipMsg="Los Alumnos o Ex alumnos"
          />
          <FormControlTrambolico
            edit={edit}
            label="Nuevos:"
            name="nuevos"
            value={data.nuevos ?? 0}
            handleChange={handleChange}
            tooltipMsg="Prospectos Nuevos del dia"
          />
          <FormControlTrambolico
            edit={false}
            label="Ventas:"
            name="ventas"
            value={data.ventas ?? 0}
            handleChange={handleChange}
            tooltipMsg="Son las ventas de este día"
          />
        </SimpleGrid>

        <Flex justifyContent="center" alignItems="center">
          <BotonesEditables
            edit={edit}
            setEdit={setEdit}
            handleSubmit={handleSubmit}
            clearChanges={clearChanges}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CargaDatosDiaria;
