import axios from "axios";
import { getCookie } from "./AuthService";
import { getBaseUrl } from "./UltilsService";

const baseURL = getBaseUrl();

const axonApi = axios.create({ baseURL });

export default axonApi;

axonApi.interceptors.request.use(async (config) => {
  const token = await getCookie("token_empleados");

  if (token) {
    config.headers.common["Authorization"] = "Bearer " + token;
  }

  return config;
});
