import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import axonApi from "../../Services/AxonApi";
import React, { useRef, useState } from "react";
import { BsPersonPlusFill } from "react-icons/bs";
import { useToast } from "@chakra-ui/toast";
import ActionButton from "../../Common/ActionButton/ActionButton";

const Pending = ({ row, isDisabled, setIsDisabled }) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();
  const toast = useToast();

  const createPending = async () => {
    setIsDisabled(true);
    setBtnDisabled(true);

    //1- Validar los datos necesarios (Notificar por toast)
    if (row.email == "") {
      toast({
        title: "Datos faltantes",
        description: "Email necesario para crear pending",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return false;
    }
    if (!row.pais) {
      toast({
        title: "Datos faltantes",
        description: "Pais necesario para crear pending",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return false;
    }

    //2- Pego al endpoint
    const data = {
      email: row.email,
      nombre: row.nombre,
      apellido: row.apellido,
      celular: row.celular,
      idPais: parseInt(row.pais.id),
    };

    const url = `/internos/crm/usuarios/contactos/${row.id}/pending`;
    const res = await axonApi.post(url, data);

    //3- Informo la respuesta por toast
    toast({
      title:
        res.data.pendingAccess.type == "error"
          ? res.data.pendingAccess.message
          : "Pending creado con exito!",
      description: res.data.pendingAccess.type == "error"
        ? "Verifique que no haya errores."
        : "Link de ingreso enviado al email.",
      status: res.data.pendingAccess.type == "error" ? res.data.pendingAccess.type : "success",
      duration: 5000,
      isClosable: true,
    });

    setIsDisabled(false);
    setBtnDisabled(false);
    setIsOpen(false);
  };

  return (
    <>
      <ActionButton
        onClick={() => setIsOpen(true)}
        isDisabled={isDisabled}
        iconComponent={<BsPersonPlusFill size={25} color="206cc6" />}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
              Pending
            </AlertDialogHeader>
            <AlertDialogBody>¿Crear Pending?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => onClose()}>
                Cancelar
              </Button>
              <Button
                isDisabled={btnDisabled}
                colorScheme={"blue"}
                ml={3}
                onClick={() => createPending()}
              >
                Crear
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Pending;
