import React, { createContext } from "react";
import useBuscarDato from "../Hooks/useBuscarDato";
export const DatoContext = createContext();

export const DatoContextProvider = (props) => {
  const functions = useBuscarDato();

  return (
    <DatoContext.Provider value={{ ...functions }}>
      {props.children}
    </DatoContext.Provider>
  );
};
