import React from "react";
import { Checkbox } from "@chakra-ui/react";

import { ResultsContext } from "../../Context/restulsContext";
const CheckBoxRow = (props) => {
  //
  const { dispatchCheck, checkedRows } = React.useContext(ResultsContext);

  // console.log(checkedRows);
  const { idVenta } = props;
  //
  return (
    <Checkbox
      isChecked={checkedRows?.includes(idVenta)}
      colorScheme="blue"
      size="md"
      onChange={(e) => {
        //
        const { checked } = e.target;

        const action = checked ? "check" : "unCheck";

        dispatchCheck({ idVenta, action });
      }}
    />
  );
};

export default CheckBoxRow;
