import React, { useState, useEffect, useContext, useReducer } from "react";

import { SessionContext } from "../../Context/sessionContext";

import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { HStack, SimpleGrid, Button, Box, Text } from "@chakra-ui/react";
import CarteraCompleta from "../CarteraCompleta";

import grupoServicioValues from "../../Utils/grupoServicioValues";

import validation from "./validation";

import { mainMargin, today, aWeekAg, TwoWeekAgo } from "../../Services/UltilsService";
import { SEARCH_ADMIN } from "../../Constants/buscarDato";

import usePermisos from "../../Hooks/usePermisos";

const Index = React.memo(
  ({
    fetchDatos,
    fetchIsLoading,
    paises,
    estadosVenta,
    getServiciosByGrupo,
    setOperadoresBySupervisor,
    operadores,
    posgrados,
    carreras,
    ebooks,
  }) => {
    const [servicios, setServicios] = useState([]);
    const [formValues, setFormValues] = useState({});

    const { hasPermiso } = usePermisos();

    const [keyForm, resetForm] = useReducer((keyform) => {
      return keyform + 1;
    }, 1);

    const isAdmin = hasPermiso(SEARCH_ADMIN);

    const {
      session: { login, sector },
    } = useContext(SessionContext);

    const grupo = sector.id == 3 || sector.id == 20 ? 1 : 5;

    useEffect(() => {
      setServicios(getServiciosByGrupo(formValues.idGrupo));
    }, [formValues.idGrupo, posgrados, carreras, ebooks]);

    const initialGet = {
      desde: TwoWeekAgo,
      hasta: today,
      operador: login,
    };

    useEffect(() => {
      (async () => {
        await setOperadoresBySupervisor(login);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        await fetchDatos(initialGet);
      })();
    }, [isAdmin]);

    const handleSubmit = async () => {
      await fetchDatos({ ...formValues })
    };

    const handleChange = ({ values }) => setFormValues(values);

    return (
      <div key={keyForm}>
        <FormContainer
          handleSubmit={handleSubmit}
          validationSchema={validation}
          initialValues={{
            desde: TwoWeekAgo,
            hasta: today,
            idGrupo: grupo,
            operador: login,
          }}
          handleChange={handleChange}
        >
          <SimpleGrid columns={[1, 2, 5]} spacing={mainMargin}>
            <FormField
              name="idGrupo"
              label="Grupo"
              placeholder="Seleccione grupo"
              type="react-select"
              options={grupoServicioValues}
              labelColor="gray.500"
            />

            <FormField
              name="idServicio"
              label="Servicio"
              placeholder="Seleccione servicio"
              type="react-select"
              options={servicios}
              labelColor="gray.500"
            />

            <FormField
              name="estadoVenta"
              label="Estado venta"
              placeholder="Seleccione estado"
              type="react-select"
              options={estadosVenta}
              labelColor="gray.500"
            />
            <FormField
              bgInput="white"
              name="celular"
              label="Celular"
              placeholder="Ingrese celular"
              type="input"
              labelColor="gray.500"
            />

            <FormField
              bgInput="white"
              name="email"
              label="Email"
              placeholder="Ingrese email"
              type="input"
              labelColor="gray.500"
            />

            <FormField
              bgInput="white"
              name="nombre"
              label="Nombre"
              placeholder="Ingrese nombre"
              type="input"
              labelColor="gray.500"
            />

            <FormField
              bgInput="white"
              name="apellido"
              label="Apellido"
              placeholder="Ingrese apellido"
              type="input"
              labelColor="gray.500"
            />

            <FormField
              bgInput="white"
              name="observaciones"
              label="Observaciones"
              placeholder="Ingrese observaciones"
              type="input"
              labelColor="gray.500"
            />

            {isAdmin && (
              <>
                <FormField
                  name="idPais"
                  label="Pais"
                  placeholder="Seleccione pais"
                  type="react-select"
                  options={paises}
                  labelColor="gray.500"
                />

                <FormField
                  name="operador"
                  label="Operador"
                  placeholder="Seleccione operador"
                  type="react-select"
                  options={operadores}
                  labelColor="gray.500"
                />
                <FormField
                  bgInput="white"
                  name="desde"
                  label="Fecha desde"
                  placeholder="Seleccione desde"
                  type="date"
                  labelColor="gray.500"
                />
                <FormField
                  bgInput="white"
                  name="hasta"
                  label="Fecha hasta"
                  placeholder="Seleccione hasta"
                  type="date"
                  labelColor="gray.500"
                />
              </>
            )}
          </SimpleGrid>

          <HStack mt={mainMargin} spacing={5}>
            <Box>
              <FormField
                name="submit"
                type="submit"
                placeholder="Buscar"
                bgInput="orange.500"
                colorScheme="orange.500"
                disabled={false}
                labelColor="gray.500"
                size="sm"
                isLoading={fetchIsLoading}
              />
            </Box>
            <CarteraCompleta />
            <Box>
              <Button
                variant="link"
                mt="24.5px"
                size="sm"
                onClick={resetForm}
                colorScheme="orange"
              >
                Limpiar filtros
              </Button>
            </Box>
          </HStack>
        </FormContainer>
        <Text fontSize="sm" mt="5" color="gray.400">
          ⚠️ La busqueda por celular y por email no estan relacionadas con el
          operador.
        </Text>
      </div>
    );
  }
);

export default Index;
