import { RiFileUserFill } from "react-icons/ri"
import { HiDocumentReport } from "react-icons/hi"
import { FaLink } from "react-icons/fa"
import { AiFillPushpin } from "react-icons/ai"
import { IoMdSettings } from "react-icons/io"

export const routesDataSidebar = [
  {
    name: "Datos",
    path: "/dato",
    icon: <RiFileUserFill />
  },
  {
    name: "Reportes",
    path: "/reportes",
    icon: <HiDocumentReport />,
    children: [
      {
        name: "Listado de Ventas",
        path: "/listado-ventas"
      },
      {
        name: "Listado de Asignaciones",
        path: "/listado-asignaciones"
      },
      {
        name: "Listado de Operador",
        path: "/listado-operador"
      },
      {
        name: "Listado de Congelados",
        path: "/listado-congelados",
        idPermiso: 391
      },
      {
        name: "Listado de Cascada",
        path: "/listado-cascada",
        idPermiso: 395
      }
    ]
  },
  // {
  //   name: "GenerarLink",
  //   path: "/generacion-link",
  //   icon: <FaLink />
  // },
  {
    name: "Util",
    path: "/util",
    icon: <AiFillPushpin />
  },
  {
    name: "Configuración",
    path: "/configuracion",
    icon: <IoMdSettings />,
    children: 
    [
    {
      name: "Whatsapp Carrousel",
      path: "/whatsapp-carrousel",
      idPermiso: 433
    },
    // {
    //   name: "Cuotas LP",
    //   path: "/cuotas-lp",
    // }
    ]
  }
]