import React, { useEffect } from 'react'
import { RiEditBoxLine } from "react-icons/ri";
import { MdDone, MdClear } from "react-icons/md";
import { IconButton, Box, Tooltip, Input, Flex} from "@chakra-ui/react";

const BotonesEditables = ({ handleSubmit, setEdit, edit, clearChanges }) => {

    return (
        <Flex>  
        {
            edit
            ?   <>
                    <Tooltip label="Aceptar">        
                        <IconButton 
                            fontSize="15px" 
                            ml={2} 
                            size="xs" 
                            icon={<MdDone />}
                            onClick={() => {
                                setEdit(!edit)
                                handleSubmit()
                            }} 
                        />
                    </Tooltip>
                    <Tooltip label="Cancelar"> 
                        <IconButton 
                            fontSize="15px" 
                            ml={2} 
                            size="xs" 
                            icon={<MdClear />}
                            onClick={() => { 
                                setEdit(!edit)
                                clearChanges()
                            }} 
                        />
                    </Tooltip>
                </>
            :   
                <Tooltip label="Editar"> 
                    <IconButton 
                        fontSize="15px" 
                        ml={2} 
                        size="xs" 
                        icon={<RiEditBoxLine />}
                        onClick={() => { 
                            setEdit(!edit)
                        }} 
                    />
                </Tooltip>
        }
            
        </Flex>
    )
}


export default BotonesEditables
