import React, { useState, useContext, useEffect } from "react";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { UtilDataContext } from "../../../Context/utilDataContext";
import { Box, SimpleGrid, Badge } from "@chakra-ui/layout";

import { validation } from "./validation";
import { generateLink } from "../../../Services/GeneracionLinkService";
import ResultLinkCheckout from "../ResultLinkCheckout";
import usePaises from "../../../Hooks/usePaises";
import { parseDataLink } from "../../../Utils/utilFunctions";

const FormularioSena = ({
  idServicio,
  idVenta,
  email,
  id: idContacto,
  pais,
  valorSena,
  grupo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [paisSeleccionado, setPaisSeleccionado] = useState();
  const [resultLink, setResultLink] = useState(null);

  const { getAllServices, paises } = useContext(UtilDataContext);
  const { paises: paisesCompletos } = usePaises(false, false);

  const initialValues = {
    idServicio: idServicio ?? null,
    email: email ?? null,
    idPais: pais ? pais.id : null,
    valorSena: valorSena ?? null,
    idAula: grupo == 1 ? 19 : grupo == 5 ? 18 : null,
  };

  const handleChange = ({ values }) => {
    setFormValues(values);

    if (values.idPais) {
      setPaisSeleccionado(
        paisesCompletos.find((pais) => pais.id == values.idPais)
      );
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const data = parseDataLink({ ...formValues }, "sena");
      const link = await generateLink(data);
      setResultLink(link);
    } catch (error) {
      setResultLink(error);
    }
    setIsLoading(false);
  };

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      handleChange={handleChange}
      validationSchema={validation}
    >
      <SimpleGrid columns={[1, 2, 4]} spacing={5}>
        <FormField
          isRequired
          disabled={initialValues.idServicio}
          name="idServicio"
          label="Servicio"
          placeholder="Seleccione servicio"
          type="react-select"
          labelColor="gray.500"
          bgInput="white"
          options={getAllServices().filter(
            (service) => service.value != 3
          )}
        />
        <FormField
          isRequired
          disabled={initialValues.idPais}
          name="idPais"
          label="Pais"
          placeholder="Seleccione pais"
          type="react-select"
          labelColor="gray.500"
          bgInput="white"
          options={paises}
        />

        <FormField
          name="email"
          disabled={initialValues.email}
          label="Email"
          placeholder="Ingrese email"
          labelColor="gray.500"
          bgInput="white"
          type="input"
          isRequired
        />

        <Box>
          <FormField
            name="valorSena"
            type="input"
            isRequired
            label={`Valor de seña (Precio local${
              paisSeleccionado ? `: ${paisSeleccionado.moneda})` : ")"
            }`}
            placeholder="Ingrese valor de la seña"
            labelColor="gray.500"
            bgInput="white"
          />
          {paisSeleccionado && formValues.valorSena ? (
            <Badge mt={2} colorScheme="green">
              $
              {(formValues.valorSena * paisSeleccionado.coefTipoCambio).toFixed(
                2
              )}{" "}
              ARS
            </Badge>
          ) : null}
        </Box>
      </SimpleGrid>

      <Box>
        <FormField
          name="submit"
          type="submit"
          placeholder="Generar link"
          bgInput="orange.500"
          colorScheme="orange.500"
          size="sm"
          isLoading={isLoading}
        />
      </Box>

      {resultLink && (
        <ResultLinkCheckout
          codeStatus={resultLink.status}
          title={
            resultLink.status == 200 ? "Link generado con éxito." : "Error!"
          }
          message={
            resultLink.status == 200
              ? resultLink.data?.checkoutAccess?.url ?? resultLink.url
              : resultLink.data.message
          }
          idContacto={resultLink.data?.idContacto ? resultLink.data.idContacto : null}
        />
      )}
    </FormContainer>
  );
};

export default FormularioSena;
