import { Button, Flex, useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { DatoContext } from "../../Context/datoContext";
import {
  postCargaDato,
  validateCuotasPagas,
} from "../../Services/CargaDatoService";
import { today } from "../../Services/UltilsService";

export const ModalCarrera = ({ dato, login, onClose }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const { hideIcon, pushNewDato } = useContext(DatoContext);

  const createDatoCarrera = async () => {
    setIsLoading(true);

    try {
      await validateCuotasPagas(dato.idVenta);
    } catch (error) {
      toast({
        title: "Error al cargar dato!",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return onClose();
    }

    const { apellido, celular, email, pais, nombre } = dato;
    const values = {
      apellido,
      nombre,
      celular,
      email,
      idPais: pais.id,
      idServicio: dato.idServicio == 2 ? 3 : 10,
      fechaCarga: today,
      usuarioResponsable: login,
    };

    try {
      const res = await postCargaDato(values);

      toast({
        title: "Dato cargado!",
        description: res.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      await hideIcon(dato.idVenta, dato.fecha);

      await pushNewDato(res.idVenta, values.fechaCarga, true);
    } catch (e) {
      toast({
        title: "Error al cargar dato!",
        description: e.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <Flex justifyContent={"end"}>
      <Button mr={2} onClick={onClose} isDisabled={isLoading}>
        Cancelar
      </Button>
      <Button
        colorScheme={"blue"}
        onClick={createDatoCarrera}
        isLoading={isLoading}
      >
        Crear dato
      </Button>
    </Flex>
  );
};
