import React, { useContext, useEffect, useState } from "react";

import { ModalAxon } from "@axontraining-sistemas/lib-common";
import { Button } from "@chakra-ui/react";
import { FaCalculator } from "react-icons/fa";
import { PlanCuotasContext } from "../../Context/PlanCuotasContext";
import PlanCuotas from "./index";

const ModalPlanCuotas = () => {
  const {
    planDeCuotas,
    postPlanDecuotas,
    monedaLocal,
    deletePlanDeCuotas,
    fetchPlanDeCuotas,
    subtitulo,
  } = useContext(PlanCuotasContext);

  return (
    <>
      {planDeCuotas && (
        <ModalAxon
          title={`Plan de cuotas`}
          size="2xl"
          trigger={
            <Button
              colorScheme="blue"
              shadow="sm"
              size="sm"
              variant="outline"
              borderWidth={2}
            >
              <FaCalculator />
              <span style={{ marginLeft: 10 }}>Plan de cuotas</span>
            </Button>
          }
          render={({ onClose, isOpen }) => (
            <PlanCuotas
              planDeCuotas={planDeCuotas}
              postPlanDecuotas={postPlanDecuotas}
              deletePlanDeCuotas={deletePlanDeCuotas}
              fetchPlanDeCuotas={fetchPlanDeCuotas}
              monedaLocal={monedaLocal}
              onClose={onClose}
              isOpen={isOpen}
              subtitulo={subtitulo}
            />
          )}
        />
      )}
    </>
  );
};

export default ModalPlanCuotas;
