import * as Yup from "yup";

export const validation = Yup.object().shape({
  idServicio: Yup.string().required("Campo requerido.").nullable(),
  idAula: Yup.string().when(["idServicio"], {
    is: (idServicio) => idServicio != 20 && idServicio != 86,
    then: Yup.string().required("Campo requerido.").nullable(),
    otherwise: Yup.string().when(["segundaEtapa"], {
      is: (segundaEtapa) => segundaEtapa,
      then: Yup.string().required("Campo requerido."),
    }),
  }),
  idPais: Yup.number().required("Campo requerido.").nullable(),
  operador: Yup.string().required("Campo requerido.").nullable(),
  segundaEtapa: Yup.boolean().notRequired().nullable(),
  sinEmail: Yup.boolean().notRequired().nullable(),
});
