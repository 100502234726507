import React from "react";
import {
  Tr,
  Td,
  Center,
  Circle,
  useDisclosure,
} from "@chakra-ui/react";
import { fechaAmigable } from "../../Services/UltilsService";

const Historia = ({ historia }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Tr  _hover={{cursor:"pointer"}} onClick={onToggle}>
        <Td p={0}>
          <Center fontSize="0.75rem">{fechaAmigable(historia.fecha)} </Center>
        </Td>
        <Td p={0}>
          <Center fontSize="0.75rem"> {historia.loginEmpleado} </Center>
        </Td>
        <Td p={0}>
          <Center fontSize="0.75rem">{historia.area}</Center>
        </Td>
        <Td p={0}>
          <Center fontSize="0.75rem">{historia.operacion}</Center>
        </Td>
        <Td p={0}>
          <Center fontSize="0.75rem">{historia.paso}</Center>
        </Td>
        <Td p={0}>
          <Center fontSize="0.75rem">{historia.pasoSiguiente}</Center>
        </Td>
        <Td>
          <Circle size="9px" bg={historia.color}></Circle>
        </Td>
      </Tr>
      {isOpen && historia.comentario && (
        <Td
          colSpan="7"
          bg="gray"
          textAlign="center"
          color="white"
          fontSize="sm"
        >
          {historia.comentario}
        </Td>
      )}
    </>
  );
};

export default Historia;
