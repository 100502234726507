import * as Yup from "yup";

export default Yup.object().shape({
  login: Yup.string().required("Required"),
  whatsapp: Yup.string().required("Required").max(13, "El número ingresado es incorrecto"),
  grupo: Yup.string().required("Required"),
  sector: Yup.string().required("Required"),
  asignadosTopeDiario: Yup.string().required("Required"),
  activo: Yup.number().required("Required"),
});
