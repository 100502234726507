import React, { useState, useContext, useEffect } from "react";

import {
  Box,
  Flex,
  Button,
  SimpleGrid,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import {
  getAcuerdo,
  validarDatosCuotas,
  validarCantidadCuotas,
  formateoCuotas,
} from "../../Services/PlanCuotasService";
import usePaises from "../../Hooks/usePaises";
import RowCuota from "./RowCuota";
import { fechaAmigable } from "../../Services/UltilsService";

const PlanCuotas = ({
  onClose,
  planDeCuotas,
  postPlanDecuotas,
  monedaLocal,
  deletePlanDeCuotas,
  fetchPlanDeCuotas,
  subtitulo,
}) => {
  const [cuotas, setCuotas] = useState([]);
  const [acuerdo, setAcuerdo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { paises } = usePaises();
  const toast = useToast();
  const maxRows = 4;

  useEffect(() => {
    setInitialCuotas();
  }, [paises, monedaLocal, planDeCuotas]);

  const setInitialCuotas = () => {
    const cuotasFormateadas = formateoCuotas(
      planDeCuotas,
      paises,
      monedaLocal,
      maxRows
    );
    setCuotas(cuotasFormateadas);
    setAcuerdo(getAcuerdo(cuotasFormateadas, paises, monedaLocal));
  };

  const handleChangePlanCuotas = (cuota, key) => {
    // Aca seteo una COPIA del array y del objeto de cuota
    let cuotasEditadas = [...cuotas];
    cuotasEditadas[key] = { ...cuota };

    setCuotas(cuotasEditadas);
    setAcuerdo(getAcuerdo(cuotasEditadas, paises, monedaLocal));
  };

  const handleSubmit = async (pais) => {
    setIsLoading(true);
    // Validamos cuotas
    const cuotasLimpias = validarDatosCuotas(cuotas);
    const cantidadCuotasValidas = validarCantidadCuotas(cuotasLimpias);

    if (!cantidadCuotasValidas) {
      toast({
        title: "Error al cargar plan de pago.",
        description: "No puede superar las 12 cuotas",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      setIsLoading(false);
      return false;
    }

    if (cuotasLimpias.length == 0) {
      toast({
        title: "Error al cargar plan de pago.",
        description: "Revise que el formulario no contenga errores.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      setIsLoading(false);
      return false;
    }

    // Reordenamos ORDEN en cuotas
    cuotasLimpias.forEach((element, i) => {
      cuotasLimpias[i].orden = i + 1;
    });

    // Formateamos data
    const data = {
      idLinea: 1,
      monedaLocal: monedaLocal,
      observaciones: "",
      cuotas: cuotasLimpias,
    };

    // Generamos plan de cuotas
    try {
      const res = await postPlanDecuotas(data);

      toast({
        title: "Plan de cuotas guardado!",
        description: "Plan de cuotas guardado con exito.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (e) {
      toast({
        title: "Error al guardar plan de cuotas",
        description: "Error al guardar plan de cuotas",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const res = await deletePlanDeCuotas();

      toast({
        title: "Plan de cuotas eliminado con éxito!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      fetchPlanDeCuotas();
      onClose();
    } catch (error) {
      toast({
        title: "Hubo un error al eliminar el plan de cuotas.",
        description: "Es posible que no haya un plan de cuotas que eliminar.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsDeleting(false);
  };

  const getRows = () => {
    let rows = [];

    for (let i = 0; i < maxRows; i++) {
      rows.push(
        <RowCuota
          key={i}
          id={i}
          cuota={cuotas[i]}
          paises={paises}
          handleChangePlanCuotas={handleChangePlanCuotas}
          monedaLocal={monedaLocal}
        />
      );
    }

    return rows;
  };

  return (
    <Box w="90%" mx="auto">
      {subtitulo && (
        <Box marginBottom={5}>
          <p dangerouslySetInnerHTML={{__html: subtitulo}} />
        </Box>
      )}
      <SimpleGrid columns={3} spacing={3}>
        <FormLabel color="gray.500" fontSize="sm" height="15px">
          Cantidad de cuotas
        </FormLabel>
        <FormLabel color="gray.500" fontSize="sm" height="15px">
          Importe de cuotas {monedaLocal}
        </FormLabel>
        <FormLabel color="gray.500" fontSize="sm" height="15px">
          Importe de cuotas ARS
        </FormLabel>
      </SimpleGrid>

      <Box>{cuotas.length > 0 && getRows()}</Box>

      <Flex mt={5} mb={4} justifyContent="end" alignItems="end">
        <Box>
          <FormLabel color="gray.500" fontSize="sm" height="15px">
            Acuerdo total en ARS
          </FormLabel>
        </Box>
        <Box>
          <Input isDisabled size="sm" value={acuerdo} />
        </Box>
      </Flex>

      <Flex mt={5} mb={4} justifyContent="space-between" alignItems="end">
        <Box>
          <Button
            name="delete"
            type="delete"
            bg="red.500"
            colorScheme="red.500"
            size="sm"
            onClick={() => handleDelete()}
            isLoading={isDeleting}
          >
            Borrar
          </Button>
        </Box>

        <Box>
          <Button
            name="submit"
            type="submit"
            bg="orange.500"
            colorScheme="orange.500"
            size="sm"
            onClick={() => handleSubmit()}
            isLoading={isLoading}
          >
            Guardar
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default PlanCuotas;
