import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useContext } from "react";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import {
  firstDayOfTheMonth,
  mainMargin,
  today,
} from "../../../Services/UltilsService";
import { validation } from "./validation";
import { productos } from "./productos";
import { CascadaContext } from "../../../Context/cascadaContext";

const FiltroCascada = () => {
  const { loading, handleChange, handleSubmit } =
    useContext(CascadaContext);
  return (
    <div>
      <FormContainer
        handleSubmit={handleSubmit}
        validation={validation}
        initialValues={{
          desde: firstDayOfTheMonth,
          hasta: today,
        }}
        handleChange={handleChange}
      >
        <SimpleGrid columns={[1, 2, 3]} spacing={mainMargin}>
          <FormField
            bgInput="white"
            isRequired
            name="desde"
            label="Fecha desde"
            placeholder="Seleccione desde"
            type="date"
            labelColor="gray.500"
          />

          <FormField
            bgInput="white"
            isRequired
            name="hasta"
            label="Fecha hasta"
            placeholder="Seleccione hasta"
            type="date"
            labelColor="gray.500"
          />

          <FormField
            name="producto"
            label="Producto"
            placeholder="Todos"
            type="react-select"
            labelColor="gray.500"
            bgInput="white"
            options={productos}
          />
        </SimpleGrid>

        <Box pl="2">
          <FormField
            name="submit"
            type="submit"
            placeholder="Buscar"
            bgInput="orange.500"
            colorScheme="orange.500"
            labelColor="gray.500"
            size="md"
            isLoading={loading}
          />
        </Box>
      </FormContainer>
    </div>
  );
};

export default FiltroCascada;
