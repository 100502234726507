import { Button } from "@chakra-ui/button";
import React from "react";

const ActionButton = ({
  size = "sm",
  bgc = "transparent",
  onClick,
  iconComponent,
  isDisabled = false,
}) => {
  return (
    <Button
      m={0}
      p={0}
      size={size}
      _focus={{ shadow: false }}
      _active={false}
      _hover="none"
      backgroundColor={bgc}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {iconComponent}
    </Button>
  );
};

export default ActionButton;
