import React from "react";

const NotFound = ({ title = "¡Página no encontrada!" }) => {
  return (
    <>
      <div class="container-fluid">
        <div class="hero">
          <div class="row">
            <div class="col-12">
              <h1 class="titulo mb-3 text-danger">
                ⛔<b> Error 404 </b>⛔
              </h1>
              <h1 className="titulo ">{title}</h1>
              {/*   <h3>Revisa la URL de la página </h3> */}
              <h2 class="subtitulo">
                {/* Puede que la página solicitada ya no exista, haya cambiado de
                nombre, o no esté disponible temporalmente. */}
                Revisa que la URL no tenga errores de tipeo.
              </h2>
            </div>
          </div>
          <div className="col-12 d-flex  justify-content-center pl-6">
            <img
              src="https://media.tenor.com/images/b65504dbb7eeafc34f3ae35c455cf10f/tenor.gif"
              width="20%"
              alt="not found gif"
            />
          </div>
        </div>
      </div>

      <style>
        {` 
  
        .hero {
        text-align: center;
        padding: 3rem 0 4rem 0;
        }
        .hero svg {
        height: 4rem;
        width: 4rem !important;
        margin: 2rem 0 0 0;
        }
        .hero path {   
        color: #2f7cc3;
        }
        .titulo {
        font-family: AxonFont-bold;
        font-size: 3.2rem;
        margin: 1rem 0 0rem 0;
        }
        .subtitulo {
        font-family: AxonFont;
        font-size: 1.4rem;
        margin: 2rem 8rem 2rem 8rem;
        }
        h3 {
        font-size: 1.2em;
        margin: 0.7rem 0 0 0;
        }
   
        @media only screen and (max-width: 767px) {
        .hero {
        text-align: center;
        padding: 2rem 0 3.5rem 0;
        }
        
        .titulo {
        font-family: AxonFont-bold;
        font-size: 2.2rem;
        margin: 1.2rem 0 0rem 0;
        }
        .subtitulo {
        font-family: AxonFont;
        font-size: 1rem;
        margin: 1rem 0 2rem 0;
        }
        h3 {
        font-size: 1rem;
        font-size: 1.1rem;
        margin: 0.8rem 0 0 0;
        }
     

  `}
      </style>
    </>
  );
};

export default NotFound;
