import React, { useEffect, useContext, useState } from "react";

import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { SimpleGrid, Box, FormControl, FormLabel } from "@chakra-ui/react";
import Select from 'react-select';

import {
    firstDayOfTheMonth,
    mainMargin,
    today,
} from "../../../Services/UltilsService";
import { validation } from "./validation";

import { UtilDataContext } from "../../../Context/utilDataContext";
import { SessionContext } from "../../../Context/sessionContext";
import { ResultsCongeladosContext } from "../../../Context/resultsCongeladosContext";
import usePermisos from "../../../Hooks/usePermisos";


const FiltroCongelados = () => {
  const { session: { login } } = useContext(SessionContext);
  const {estadosVenta, operadores, setOperadoresBySupervisor, getAllServices} = useContext(UtilDataContext);
  const {
    formValues,
    setFormValues,
    loading,
    retrieveDatosCongelados,
  } = useContext(ResultsCongeladosContext)
  const { hasPermiso } = usePermisos();

  useEffect(() => {
    (async () => {
      await setOperadoresBySupervisor(login);
    })();
  }, []);

  const handleSubmit = async () => {
    retrieveDatosCongelados()
  };
  
  const handleChange = ({ values }) => setFormValues(values)

  const handleChangeSelect = (values) => {
    const arrayValues = [];

    values.map(value => {
      arrayValues.push(value.value)
    })
    
    const data = formValues
    data.estados = arrayValues.toString();
    setFormValues(data)
  }

  return (
    <div>
      <FormContainer
        handleSubmit={handleSubmit}
        validationSchema={validation}
        initialValues={{
          desde: firstDayOfTheMonth,
          hasta: today,
          responsable: !hasPermiso(417) ? login : null
        }}
        handleChange={handleChange}
      >
        <SimpleGrid columns={[1, 2, 6]} spacing={mainMargin}>
            <FormField
              name="servicio"
              label="Servicio"
              placeholder="Seleccione servicio"
              type="react-select"
              options={getAllServices()}
              labelColor="gray.500"
            />
            <FormField
              name="responsable"
              label="Responsable"
              placeholder="Seleccione responsable"
              type="react-select"
              disabled={!hasPermiso(417)}
              options={operadores}
              labelColor="gray.500"
            />
            <FormField
              bgInput="white"
              name="desde"
              label="Fecha desde"
              placeholder="Seleccione desde"
              type="date"
              labelColor="gray.500"
            />

            <FormField
              bgInput="white"
              name="hasta"
              label="Fecha hasta"
              placeholder="Seleccione hasta"
              type="date"
              labelColor="gray.500"
            />
          
            <FormControl>
              <FormLabel
                htmlFor="estadoVenta"
                color="gray.500"
                fontSize="sm"
                height="15px"
              >
                Estados
              </FormLabel>
                <Select
                  placeholder="Estados"
                  isMulti
                  name="estados"
                  options={estadosVenta}
                  onChange={(e) => handleChangeSelect(e)}
                />
            </FormControl>

            <FormField
              bgInput="white"
              name="anuncio"
              label="Anuncio"
              placeholder="Nombre del anuncio"
              type="input"
              labelColor="gray.500"
            />

            <Box pl="2">
              <FormField
                name="submit"
                type="submit"
                placeholder="Buscar"
                bgInput="orange.500"
                colorScheme="orange.500"
                labelColor="gray.500"
                size="md"
                isLoading={loading}
              />
            </Box>
        </SimpleGrid>
      </FormContainer>
    </div>
  );
};

export default FiltroCongelados;
