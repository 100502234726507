import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/alert";
import { Flex, Button } from "@chakra-ui/react";
import { BiCopy } from "react-icons/bi";
import { useToast } from "@chakra-ui/toast";

const TXT_STATUS = {
  200: "success",
  400: "error",
  404: "error",
};

const ResultLinkCheckout = ({ codeStatus, title, message }) => {
  const toast = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message);
    toast({
      title: "Link copiado!",
      description: "Link copiado con exito!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex pt={5}>
      <Alert status={TXT_STATUS[codeStatus]} variant="top-accent">
        <AlertIcon />
        <AlertTitle mr={2}>{title}</AlertTitle>
        <AlertDescription maxW="480px" mr="5">{message}</AlertDescription>
        {codeStatus == 200 ? (
            <Button
              leftIcon={<BiCopy />}
              onClick={copyToClipboard}
              variant="solid"
            >
              Copiar
            </Button>
        ) : null}
      </Alert>
    </Flex>
  );
};

export default ResultLinkCheckout;
