import React from "react";
import {
  Heading,
  Box,
  Stack,
  Flex,
  Circle,
  Spacer,
  HStack,
  Icon,
} from "@chakra-ui/react";

const index = ({
  children,
  actions,
  bgColor,
  title,
  icon,
  colorScheme,
  titleSize,
  borderSize,
  marginTop,
}) => {
  const borderSizeReference = {
    sm: "2px",
    md: "4px",
    lg: "6px",
    xl: "8px",
  };
  return (
    <Stack mt={marginTop}>
      <Flex>
        <HStack spacing="10px">
          {icon ? (
            <Circle
              size="40px"
              color={colorScheme}
              borderWidth="2px"
              borderColor={colorScheme}
            >
              <Icon as={icon} w={7} h={7} />
            </Circle>
          ) : null}
          <Heading size={titleSize} color={colorScheme}>
            {title}
          </Heading>
        </HStack>
        <Spacer />

        {actions ? actions() : null}
      </Flex>

      <Box
        borderTopWidth={borderSizeReference[borderSize] || "4px"}
        borderColor={colorScheme}
        margin="0"
      >
        <Box marginTop="0" bgColor={bgColor}>
          {children}
        </Box>
      </Box>
    </Stack>
  );
};

export default index;
