import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";

const CirculoEstados = ({ background, message, m }) => {
  return (
    <Tooltip label={message} isDisabled={!message}>
      <Box
        m={m}
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          background: background,
        }}
        _hover={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
};

export default CirculoEstados;
