import axios from "axios";
import { useToast } from '@chakra-ui/react'
import React, { createContext, useState, useEffect } from "react";
import { getSesion } from "../Services/AuthService";

import * as PermisoService from "../Services/PermisoService";

export const SessionContext = createContext();

export const SessionContextProvider = (props) => {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permisos, setPermisos] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchApi = async () => {
      const result = await getSesion();
      setPermisos(await PermisoService.getPermisosNormalizados(result.id));

      setSession(result);
      setIsLoading(false);
    };
    if (!session) fetchApi();
  }, [session]);

  useEffect(() => {
    const fetchApi = async () => {
      setPermisos(await PermisoService.getPermisosNormalizados(session?.id));
    };
    fetchApi();
  }, [session]);

  const handleForgetPassword = async ({data}) => {
    const { email } = data
    try {
      const response = await axios.post(
        "https://api.axontraining.com/master/recuperar-pass-enviar-email",
        { email: email },
      )
    if (response) {
      toast({
        title: "Exito",
        description: "Se envio un mail a tu casilla, por favor revisa para poder continuar con el cambio de contraseña",
        status: "success",
        duration: 3500,
        isClosable: true,
      });
    }    
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3500,
        isClosable: true,
      });
    }
  }

  return (
    <SessionContext.Provider
      value={{ session, setSession, isLoading, setIsLoading, permisos,handleForgetPassword }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
