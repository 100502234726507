import React from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Heading,
} from "@chakra-ui/react";

const ResultsAsignaciones = ({ asignaciones }) => {

  if(!asignaciones) return null;

  return asignaciones.map((supervisor,i) => 
    <Box
      key={i}
      borderRadius="lg"
      bgColor="white"
      shadow="lg"
      mt="2vh !important"
      width="auto"
    > 
      <Heading pl={6} pb={1} pt={4} as='h4' size='md'>Supervisor: {supervisor.nombre}</Heading>
      <Box p={4} overflowX="auto">
        <Table variant="striped" colorScheme="gray" size="sm">
          <Thead>
            <Tr>
              <Th>Operador</Th>
              <Th>Asignaciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {supervisor.operadores.map((operador, i) => {
              return (
                <Tr key={i}>
                  
                  <Td>{operador.responsable}</Td>
                  <Td>{operador.asignaciones}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default ResultsAsignaciones;
