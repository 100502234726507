import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Router";
import { SessionContextProvider } from "./Context/sessionContext";
import { CommonContextProvider } from "@axontraining-sistemas/lib-common";
const App = () => {
  return (
    <div className="App">
      <SessionContextProvider>
        <CommonContextProvider>
          <Router>
            <Routes />
          </Router>
        </CommonContextProvider>
      </SessionContextProvider>
    </div>
  );
};

export default App;
