import React from "react";

import Tabs from "./Tabs";

import { DatoContextProvider } from "../../Context/datoContext";
import { UtilDataContextProvider } from "../../Context/utilDataContext";

/*
Sistema de tabs para renderizar diferentes componentes.
*/
const Index = () => {
  return (
    <>
      <UtilDataContextProvider>
        <DatoContextProvider>
          <Tabs />
        </DatoContextProvider>
      </UtilDataContextProvider>
    </>
  );
};

export default Index;
