import React from "react";
import { ReporteContext } from "../../Context/reporteContext";
import {
  Table,
  Tbody,
  Tr,
  Td,
  Box,
  Heading,
  Button,
  HStack,
} from "@chakra-ui/react";
const Totales = React.forwardRef((props, ref) => {
  const { hidePanel, totales } = React.useContext(ReporteContext);

  const { endOfPageRef, topOfPageRef } = ref;

  if (hidePanel) return null;

  return (
    <Box
      ref={endOfPageRef}
      borderRadius="lg"
      bgColor="white"
      shadow="lg"
      mt="5vh !important"
      mb="5vh !important"
    >
      <HStack p={3} justify="space-between">
        <Heading color="gray.500" size="md">
          Totales
        </Heading>
        <Button
          colorScheme="blue"
          onClick={() =>
            topOfPageRef.current.scrollIntoView({ behavior: "smooth" })
          }
        >
          Arriba
        </Button>
      </HStack>
      <Box height="5px" bg="blue.500" mt={0.5} />
      <Table variant="striped">
        <Tbody>
          {totales?.map((total) => {
            return (
              <Tr>
                <Td>{total.label}</Td>
                <Td fontWeight="medium">{total.value}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
});

export default Totales;
