import React from "react";
import { Button, Tooltip } from "@chakra-ui/react";

const Trigger = ({ showCartera }) => {
  //
  return (
    <Tooltip label="Cartera no disponible" isDisabled={showCartera}>
      <div>
        <Button
          isDisabled={!showCartera}
          variant="solid"
          mt="24.5px"
          size="sm"
          colorScheme="orange"
        >
          Mostrar cartera
        </Button>
      </div>
    </Tooltip>
  );
};

export default Trigger;
