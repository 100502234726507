import { useState, useEffect } from "react";
import Axios from "axios";

const usePaises = (idPais = false, isReactSelect = false) => {
  const [paises, setPaises] = useState([]);

  const getPaises = async () => {
    const res = await Axios.get(
      "https://api.axontraining.com/master/paises"
    );
    return res.data;
  };

  const findPaisById = (idPais, paises) => {
    const pais = paises.filter((pais) => {
      return parseInt(pais.id) === idPais;
    });
    return pais[0];
  };

  const formatToReactSelect = (array) => {
    return array.map((pais) => {
      return { value: pais.id, label: pais.nombre };
    });
  };

  const getBandera = (idPais) => {
    const pais = findPaisById(parseInt(idPais), paises);
    if (pais) {
      return pais.bandera;
    }
    return null;
  };

  useEffect(() => {
    (async () => {
      const paises = await getPaises();
      if (idPais) {
        const pais = findPaisById(idPais, paises);
        if (isReactSelect) return setPaises(formatToReactSelect(pais));
        setPaises(pais);
      } else {
        if (isReactSelect) return setPaises(formatToReactSelect(paises));
        setPaises(paises);
      }
    })();
  }, [idPais, isReactSelect]);

  return { paises, getBandera };
};

export default usePaises;
