import React, { useState, useContext } from "react";
import logo from "../../Static/images/logo_menu.png";
import "../../Static/css/login.css";
import { SessionContext } from "../../Context/sessionContext";
import { login } from "../../Services/AuthService";
import { Button, Center } from "@chakra-ui/react";

export const Login = () => {
  const [error, setError] = useState({});
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { setSession,handleForgetPassword } = useContext(SessionContext);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const ingresar = async () => {
    let res = await login(data);
    if (res.type === "error") {
      setError({ message: res.message });
    } else {
      setSession(res);
    }
  };
  return (
    <>
      <div className="ingreso">
        <div className="wrapper fadeInDown pl-0 pr-0 mt-5">
          <div id="formContent">
            <div className="fadeIn first ">
              <Center>{<img src={logo} id="icon" alt="User Icon" />}</Center>
            </div>
            <hr />
            <input
              type="text"
              id="login"
              className={`fadeIn second ${error.message && "formInput-error"}`}
              name="email"
              value={data.email}
              placeholder="Email"
              onChange={handleInputChange}
            />
            <input
              type="password"
              id="password"
              className={`fadeIn third ${error.message && "formInput-error"}`}
              name="password"
              value={data.password}
              placeholder="Contraseña"
              onChange={handleInputChange}
              style={{ marginBottom: 0 }}
            />

            {error.message && (
              <div className="col-12">
                <small className="text-danger fadeIn">{error.message}</small>
              </div>
            )}

            {/* <input
            type="button"
            className="fadeIn fourth"
            value="Ingresar"
            style={{ marginTop: "3rem" }}
          
          /> */}

            <Button
              size="lg"
              colorScheme="blue"
              mt={5}
              mb={5}
              onClick={ingresar}
            >
              Ingresar
            </Button>
            <div id="formFooter">
              <a
                className="underlineHover"
                style={{cursor: "pointer"}}
                onClick={() => handleForgetPassword({data})}
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
