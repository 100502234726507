import React from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import AxonContainer from "../AxonContainer/index"

const ModalAxon = ({ render, trigger, size, title, instaShow }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div onClick={onOpen}>{trigger}</div>

      <Modal
        isCentered={true}
        closeOnOverlayClick={false}
        isOpen={isOpen || instaShow}
        onClose={onClose}
        size={size}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <AxonContainer
              title={title}
              titleSize="md"
              colorScheme="blue.500"
              bgColor="gray.100"
            ></AxonContainer>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{render({ isOpen, onOpen, onClose })}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAxon;
