import React, { useContext, useEffect, useReducer, useState } from "react";
import { SessionContext } from "../../Context/sessionContext";

import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { SimpleGrid, Box } from "@chakra-ui/react";

import {
  firstDayOfTheMonth,
  mainMargin,
  today,
} from "../../Services/UltilsService";

import { UtilDataContext } from "../../Context/utilDataContext";
import { ReporteContext } from "../../Context/reporteContext";
import { useToast } from "@chakra-ui/toast";

import usePermisos from "../../Hooks/usePermisos";

import { SEARCH_ADMIN } from "../../Constants/buscarDato";
//
const FiltroReporte = () => {
  //
  const [formValues, setFormValues] = useState({});

  const { hasPermiso } = usePermisos();

  const isAdmin = hasPermiso(SEARCH_ADMIN);

  const toast = useToast();

  const { operadores, setOperadoresBySupervisor } = useContext(UtilDataContext);

  const {
    session: { login },
  } = useContext(SessionContext);

  const { isFetching, getReporteVentas } = useContext(ReporteContext);

  useEffect(() => {
    (async () => {
      await setOperadoresBySupervisor(login);
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await getReporteVentas(formValues);
    } catch (error) {
      toast({
        title: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = ({ values }) => setFormValues(values);

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      initialValues={{
        desde: firstDayOfTheMonth,
        hasta: today,
        operador: login,
      }}
      handleChange={handleChange}
    >
      <SimpleGrid columns={[1, 2, 5]} spacing={mainMargin}>
        {isAdmin && (
          <FormField
            name="operador"
            label="Operador"
            placeholder="Seleccione operador"
            type="react-select"
            options={operadores}
            labelColor="gray.500"
          />
        )}
        <FormField
          bgInput="white"
          name="desde"
          label="Fecha desde"
          placeholder="Seleccione desde"
          type="date"
          labelColor="gray.500"
        />
        <FormField
          bgInput="white"
          name="hasta"
          label="Fecha hasta"
          placeholder="Seleccione hasta"
          type="date"
          labelColor="gray.500"
        />
        <Box pl="2">
          <FormField
            name="submit"
            type="submit"
            placeholder="Buscar"
            bgInput="orange.500"
            colorScheme="orange.500"
            labelColor="gray.500"
            size="md"
            isLoading={isFetching}
          />
        </Box>
      </SimpleGrid>
    </FormContainer>
  );
};

export default FiltroReporte;
