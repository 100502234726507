import * as Yup from "yup";

export const editValidation = Yup.object().shape({
  idServicio: Yup.string(),
  fechaCarga: Yup.string(),
  celular: Yup.string(),
  idPais: Yup.number(),
  apellido: Yup.string(),
  email: Yup.string().email("Email inválido"),
});
