import React, { useContext } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import NotFound from "../Common/Layout/NotFound";
import { Wrapper } from "@axontraining-sistemas/lib-common";
import { routesDataSidebar } from "./routesDataSidebar";
import { logout as rawLogout } from "../Services/AuthService";

import { Spinner } from "@chakra-ui/spinner";
import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/react";

// Context
import { SessionContext } from "../Context/sessionContext";

// Componentes
import Login from "../Components/Login";
import Tabs from "../Components/Tabs";
import Reportes from "../Components/Reportes";
import GeneracionLink from "../Components/GeneracionLink";
import ReporteOperador from "../Components/ReporteOperador";
import ReporteAsignaciones from "../Components/Reportes/asignaciones/ReporteAsignaciones";
import ReporteCongelados from "../Components/Reportes/salidaCongelados/ReporteCongelados";
import ReporteCascada from "../Components/Reportes/cascada/ReporteCascada";
import Util from "../Components/Util/index";
import WhatsappCarrousel from "../Components/WhatsappCarrousel";
import CuotasLp from "../Components/CuotasLp";

export const Routes = () => {
  const { session, isLoading, permisos, setSession } =
    useContext(SessionContext);
  const history = useHistory();

  const logout = () => {
    rawLogout();
    setSession({});
  };

  const redirectTo = (pathname) => {
    history.push(pathname);
  };

  //Rutas que requieren session
  const privateRoute = (Component) => (props) => {
    if (session.id) {
      return <Component {...props} />;
    }
    return <Redirect to="/login" />;
  };

  const AnonymousRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        !session.id ? <Component {...props} /> : <Redirect to="/dato" />
      }
    />
  );

  if (!isLoading && session && session.id) {
    axios.get("https://api.axontraining.com/master/sector").then((res) => {
      if (!res.data.id) {
        window.location.href = "https://www.axontraining.com";
        return;
      }
    });
  }

  if (isLoading)
    return (
      <Center>
        <Spinner
          color="blue.500"
          mt="4rem"
          size="xl"
          thickness="10px"
          emptyColor="red.100"
        />
      </Center>
    );

  return (
    <Wrapper
      session={session}
      permisos={permisos}
      data={routesDataSidebar}
      logout={logout}
      redirectTo={redirectTo}
    >
      <Box p={2}></Box>
      <Switch>
        <AnonymousRoute exact path="/login" component={Login} />

        <Route exact path="/" render={privateRoute(Tabs)} />

        <Route exact path="/dato" render={privateRoute(Tabs)} />

        <Route
          exact
          path="/reportes/listado-operador"
          render={privateRoute(ReporteOperador)}
        />

        <Route
          exact
          path="/reportes/listado-ventas"
          render={privateRoute(Reportes)}
        />

        <Route
          exact
          path="/reportes/listado-asignaciones"
          render={privateRoute(ReporteAsignaciones)}
        />

        <Route
          exact
          path="/reportes/listado-congelados"
          render={privateRoute(ReporteCongelados)}
        />

        <Route 
          exact
          path="/reportes/listado-cascada"
          render={privateRoute(ReporteCascada)}
        />

        <Route
          exact
          path="/generacion-link"
          render={privateRoute(GeneracionLink)}
        />

        <Route
          exact
          path="/util"
          render={privateRoute(Util)}
        />

        <Route
        exact
        path="/configuracion/whatsapp-carrousel"
        render={privateRoute(WhatsappCarrousel)}/>
        
        {/* <Route
        exact
        path="/configuracion/cuotas-lp"
        render={privateRoute(CuotasLp)}/> */}

        <Route component={NotFound} />
      </Switch>
    </Wrapper>
  );
};