import { useState, useEffect } from "react";

import axonApi from "../Services/AxonApi";

const usePlanDeCuotas = (idVenta, pais) => {
  const [planDeCuotas, setPlanDeCuotas] = useState();
  const [monedaLocal, setMonedaLocal] = useState("");
  const [subtitulo, setSubtitulo] = useState();

  useEffect(() => {
    fetchPlanDeCuotas();
    fetchPrimeraEtapa();
  }, []);

  const fetchPlanDeCuotas = async () => {
    const url = `/ventas/${idVenta}/ordenes-de-pago`;

    try {
      const { data } = await axonApi.get(url);

      if (data.error) {
        setMonedaLocal(pais.moneda);
        setPlanDeCuotas([]);
        throw data.error;
      }

      setPlanDeCuotas(data.cuotas);
      setMonedaLocal(data.monedaLocal);
    } catch (error) {
      // Endpoint viejo no tira 400 por lo que no entra en este catch
      setPlanDeCuotas([]);
    }
  };

  const fetchPrimeraEtapa = async () => {
    const url = `/ventas/${idVenta}/primera-etapa`;

    try {
      const { data } = await axonApi.get(url);
      setSubtitulo(data.response);
    } catch (error) {}
  };

  const postPlanDecuotas = async (datos) => {
    const url = `/ventas/${idVenta}/ordenes-de-pago`;

    try {
      const { data } = await axonApi.post(url, datos);
      setPlanDeCuotas(data.cuotas);
    } catch (error) {
      setPlanDeCuotas([]);
      throw error.response.data;
    }
  };

  const deletePlanDeCuotas = async () => {
    const url = `/ventas/${idVenta}/ordenes-de-pago`;

    try {
      const res = await axonApi.delete(url);
      return res.status;
    } catch (error) {
      throw error.response;
    }
  };

  return {
    planDeCuotas,
    postPlanDecuotas,
    monedaLocal,
    deletePlanDeCuotas,
    fetchPlanDeCuotas,
    subtitulo,
  };
};

export default usePlanDeCuotas;
