import axios from "axios";

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();

  if (process.env.NODE_ENV !== "development") {
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";domain=.axontraining.com;path=/";
  } else {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
};

export const token = getCookie("token");
if (token !== "null" && token !== "undefined") {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export const getSesion = async () => {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + getCookie("token_empleados");
  try {
    const res = await axios.get("https://api.axontraining.com/master/sesion");
    return res.data;
  } catch (error) {
    return {
      type: "error",
      message: error.response.data.message,
    };
  }
};

export const login = async (credenciales) => {
  try {
    const res = await axios.post(
      "https://api.axontraining.com/system/login",
      credenciales
    );

    setCookie("token_empleados", res.data.token, 1);

    axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;

    const res2 = await axios.get("https://api.axontraining.com/master/sesion");

    return res2.data;
  } catch (error) {
    if (error.request.status === 401) {
      return {
        type: "error",
        message: error.response.data.message,
      };
    }
    return {
      type: "error",
      message: "Ha habido un error.",
    };
  }
};

export const loguearseComoUsuario = async (idUsuario) => {
  const idUsuarioEncripted = btoa(idUsuario);
  try {
    let {
      data: { redirectUrl },
    } = await axios.post(
      "https://api.axontraining.com/checkout/redireccion-campus",
      {
        idUsuario: idUsuarioEncripted,
      }
    );

    window.location.assign(redirectUrl);
  } catch (error) {
    return error.response.data;
  }
};

export const logout = () => {
  setCookie("token_empleados", null);
};
