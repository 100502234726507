import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Flex,
  Button,
  Spacer,
} from "@chakra-ui/react";
import ModalAxon from "../Modal";
import AgregarAsesor from "./AgregarAsesor";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaPencilAlt } from "react-icons/fa";
import axonApi from "../../Services/AxonApi";

const WhatsappTable = ({ usersData, getUsers}) => {
  const [inactiveTable, setInactiveTable] = useState(false);
  const [gruposAsesores, setGruposAsesores] = useState([]);
  const [sectores, setSectores] = useState([]);

  const getGrupos = async () => {
    await axonApi
      .get("internos/crm/grupo-asesores")
      .then((res) => setGruposAsesores(res.data.data));
  };

  const getSectores = async () => {
    await axonApi
      .get("internos/gestion/per-sectores")
      .then((res) => setSectores(res.data.data));
  };

  useEffect(() => {
    (async () => {
      await getGrupos();
      await getSectores();
    })();
  }, []);

  const centerTextStyle = {
    textAlign: "center",
  };

  return (
    <>
      <Flex mb={3} mt={3}>
        <Heading size="md" color="gray.600" m={5} ml={0}>
          Asesores Activos:
        </Heading>
        <Spacer />
        <ModalAxon
          title={"Agregar Asesor"}
          size={"xl"}
          trigger={<Button colorScheme="telegram">Agregar asesor</Button>}
          render={(props) => (
            <AgregarAsesor
              onClose={props.onClose}
              gruposAsesores={gruposAsesores}
              sectores={sectores}
              editar={false}
              getUsers={getUsers}
            />
          )}
        />
      </Flex>
      <TableContainer>
        <Table
          variant="striped"
          colorScheme="facebook"
          size="sm"
          style={{ background: "white" }}
        >
          <Thead>
            <Th style={centerTextStyle}>Login</Th>
            <Th style={centerTextStyle}>Nombre</Th>
            <Th style={centerTextStyle}>Celular</Th>
            <Th style={centerTextStyle}>Grupo</Th>
            <Th style={centerTextStyle}>Sector</Th>
            <Th style={centerTextStyle}>Asignados Hoy</Th>
            <Th style={centerTextStyle}>Tope Diario</Th>
            <Th style={centerTextStyle}>Acciones</Th>
          </Thead>
          <Tbody>
            {usersData
              ?.filter((i) => i.activo === 1)
              .map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td style={centerTextStyle}>{item?.login}</Td>
                    <Td style={centerTextStyle}>{item?.nombre}</Td>
                    <Td style={centerTextStyle}>{item?.whatsapp}</Td>
                    <Td style={centerTextStyle}>{item?.grupo?.grupo}</Td>
                    <Td style={centerTextStyle}>{item?.sector?.sector}</Td>
                    <Td style={centerTextStyle}>{item?.asignadosHoy}</Td>
                    <Td style={centerTextStyle}>{item?.asignadosTopeDiario}</Td>
                    <Td>
                      <ModalAxon
                        title={"Editar Asesor"}
                        size={"xl"}
                        trigger={
                          <FaPencilAlt
                            style={{ marginLeft: "22px", cursor: "pointer" }}
                            size={20}
                            color="#FF7000"
                          />
                        }
                        render={(props) => (
                          <AgregarAsesor
                            onClose={props.onClose}
                            userInfo={item}
                            gruposAsesores={gruposAsesores}
                            sectores={sectores}
                            grupoSelected={item?.grupo?.id}
                            sectorSelected={item?.sector?.id}
                            idAsesor={item?.id}
                            editar={true}
                            getUsers={getUsers}
                          />
                        )}
                      />
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex padding={2} mt={20} mb={5} ml={0} alignItems="center" backgroundColor="#D9DEE9">
        <Heading size="md" color="gray.600">
          Asesores Inactivos:
        </Heading>
        <Spacer />
        {inactiveTable ? (
          <IoIosArrowUp
            onClick={() => setInactiveTable((prevState) => !prevState)}
            size={40}
            color="#4A5568"
          />
        ) : (
          <IoIosArrowDown
            onClick={() => setInactiveTable((prevState) => !prevState)}
            size={40}
            color="#4A5568"
          />
        )}
      </Flex>
      {inactiveTable && (
        <TableContainer>
          <Table
            variant="striped"
            colorScheme="facebook"
            size="sm"
            style={{ background: "white" }}
          >
            <Thead>
              <Th style={centerTextStyle}>Login</Th>
              <Th style={centerTextStyle}>Nombre</Th>
              <Th style={centerTextStyle}>Celular</Th>
              <Th style={centerTextStyle}>Grupo</Th>
              <Th style={centerTextStyle}>Sector</Th>
              <Th style={centerTextStyle}>Asignados Hoy</Th>
              <Th style={centerTextStyle}>Tope Diario</Th>
              <Th style={centerTextStyle}>Acciones</Th>
            </Thead>
            <Tbody>
              {usersData
                ?.filter((i) => i.activo === 0)
                .map((item, i) => {
                  return (
                    <Tr key={i}>
                      <Td style={centerTextStyle}>{item?.login}</Td>
                      <Td style={centerTextStyle}>{item?.nombre}</Td>
                      <Td style={centerTextStyle}>{item?.whatsapp}</Td>
                      <Td style={centerTextStyle}>{item?.grupo?.grupo}</Td>
                      <Td style={centerTextStyle}>{item?.sector?.sector}</Td>
                      <Td style={centerTextStyle}>{item?.asignadosHoy}</Td>
                      <Td style={centerTextStyle}>
                        {item?.asignadosTopeDiario}
                      </Td>
                      <Td>
                        <ModalAxon
                          title={"Editar Asesor"}
                          size={"xl"}
                          trigger={
                            <FaPencilAlt
                              style={{ marginLeft: "22px", cursor: "pointer" }}
                              size={20}
                              color="#FF7000"
                            />
                          }
                          render={(props) => (
                            <AgregarAsesor
                              onClose={props.onClose}
                              userInfo={item}
                              gruposAsesores={gruposAsesores}
                              sectores={sectores}
                              grupoSelected={item?.grupo?.id}
                              sectorSelected={item?.sector?.id}
                              editar={true}
                              idAsesor={item?.id}
                              getUsers={getUsers}
                            />
                          )}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <div style={{height: "50px"}}></div>
    </>
  );
};

export default WhatsappTable;
