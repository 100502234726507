import React, { useState } from "react";

import { DatoContext } from "../Context/datoContext";
const useCheckRow = () => {
  const { fetchIsLoading } = React.useContext(DatoContext);

  const [counter, setCounter] = useState(0);

  React.useEffect(
    () => dispatchCheck({ action: "unCheckAll" }),
    [fetchIsLoading]
  );

  const checkRowsReducer = (state, payload) => {
    switch (payload.action) {
      case "check": {
        return [...state, payload.idVenta];
      }
      case "unCheck": {
        return state.filter((elm) => elm !== payload.idVenta);
      }
      case "checkAll": {
        return payload.data;
      }
      case "unCheckAll": {
        return [];
      }
      default:
        return state;
    }
  };

  const [checkedRows, dispatchCheck] = React.useReducer(checkRowsReducer, []);
  //
  React.useEffect(() => setCounter(checkedRows.length), [checkedRows]);

  return { checkedRows, dispatchCheck, counter };
};

export default useCheckRow;
