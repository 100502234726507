import React, { useState, useContext, useEffect } from "react";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { UtilDataContext } from "../../../Context/utilDataContext";
import { Box, HStack, SimpleGrid } from "@chakra-ui/layout";
import { SessionContext } from "../../../Context/sessionContext";
import { validation } from "./validation";
import useAulas from "../../../Hooks/useAulas";
import { generateLink } from "../../../Services/GeneracionLinkService";
import ResultLinkCheckout from "../ResultLinkCheckout";
import { parseDataLink } from "../../../Utils/utilFunctions";
import { PlanCuotasContextProvider } from "../../../Context/PlanCuotasContext";
import ModalPlanCuotas from "../../PlanCuotas/ModalPlanCuotas";
import usePermisos from "../../../Hooks/usePermisos";

const FormularioCompra = ({
  idServicio,
  idVenta,
  email,
  celular,
  id: idContacto,
  pais,
  responsable: operador,
  grupo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [resultLink, setResultLink] = useState(null);
  const {
    getAllServices,
    paises,
    operadores,
    setOperadoresBySupervisor,
    habilitaVentaLaboral,
  } = useContext(UtilDataContext);
  const {
    session: { login },
  } = useContext(SessionContext);
  const { hasPermiso } = usePermisos();
  const { aulas, isFetching: fetchingAulas } = useAulas(
    formValues.idServicio,
    true,
    grupo
  );

  const initialValues = {
    idServicio: idServicio ?? null,
    email: email ?? null,
    celular: celular ?? null,
    idPais: pais ? pais.id : null,
    operador: operador ?? null,
    cursoBonificado: false,
    segundaEtapa: false,
    idContacto,
  };

  useEffect(() => {
    (async () => {
      await setOperadoresBySupervisor(login);
    })();
  }, []);

  useEffect(() => {
    formValues.email = "";
  }, [formValues.sinEmail]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = parseDataLink({ ...formValues }, null, habilitaVentaLaboral);
      const link = await generateLink(data, grupo);
      setResultLink(link);
    } catch (error) {
      setResultLink(error);
    }
    setIsLoading(false);
  };

  const handleChange = ({ values }) => setFormValues(values);

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validation}
      handleChange={handleChange}
    >
      <SimpleGrid columns={[1, 2, 3]} spacing={10}>
        <Box position="relative">
          <FormField
            isRequired
            disabled={initialValues.idServicio}
            name="idServicio"
            label="Servicio"
            placeholder="Seleccione servicio"
            type="react-select"
            labelColor="gray.500"
            bgInput="white"
            options={getAllServices()}
          />

          <Box position="absolute" bottom="-45px">
            <FormField
              isRequired={false}
              name="segundaEtapa"
              placeholder="Segunda Etapa"
              type="checkbox"
              labelColor="gray.500"
              bgInput="white"
            />
          </Box>
        </Box>

        <FormField
          isRequired
          disabled={initialValues.idPais}
          name="idPais"
          label="Pais"
          placeholder="Seleccione pais"
          type="react-select"
          labelColor="gray.500"
          bgInput="white"
          options={paises}
        />

        <FormField
          isRequired
          disabled={initialValues.operador}
          name="operador"
          label="Responsable"
          placeholder="Seleccione"
          type="react-select"
          labelColor="gray.500"
          bgInput="white"
          options={operadores}
        />

        <FormField
          isRequired={
            formValues.idServicio != 20 && formValues.idServicio != 86
          }
          disabled={
            aulas.length <= 0 ||
            fetchingAulas ||
            (!formValues.segundaEtapa &&
              (formValues.idServicio == 20 || formValues.idServicio == 86))
          }
          name="idAula"
          label="Aula"
          placeholder={fetchingAulas ? "Cargando..." : "Seleccione"}
          type="react-select"
          labelColor="gray.500"
          bgInput="white"
          options={aulas}
        />

        <Box position="relative">
          <FormField
            name="email"
            label="Email"
            placeholder="Ingrese email"
            labelColor="gray.500"
            bgInput="white"
            type="input"
            disabled={formValues.sinEmail || initialValues.email}
          />
          <Box position="absolute" bottom="-45px">
            <FormField
              isRequired={false}
              name="sinEmail"
              placeholder="No conozco el email"
              type="checkbox"
              labelColor="gray.500"
              bgInput="white"
              disabled={initialValues.email}
            />
          </Box>
        </Box>

        <FormField
          name="cursoBonificado"
          label="Curso Bonificado"
          placeholder="Tiene bonificado"
          labelColor="gray.500"
          bgInput="white"
          type="react-select"
          options={[
            { value: true, label: "Si" },
            { value: false, label: "No" },
          ]}
        />
        {/* {initialValues.idContacto && hasPermiso(400) && (
          <FormField
            isRequired={false}
            name="debitoAutomatico"
            placeholder="Débito automático"
            type="checkbox"
            labelColor="gray.500"
            bgInput="white"
          />
        )} */}
      </SimpleGrid>

      <HStack mt={10} spacing={5}>
        <Box>
          <FormField
            name="submit"
            type="submit"
            placeholder="Generar link"
            bgInput="orange.500"
            colorScheme="orange.500"
            size="sm"
            isLoading={isLoading}
          />
        </Box>

        {idVenta && (
          <Box>
            <Box mt={5}></Box>
            <PlanCuotasContextProvider pais={pais} idVenta={idVenta}>
              <ModalPlanCuotas />
            </PlanCuotasContextProvider>
          </Box>
        )}
      </HStack>

      {resultLink && (
        <ResultLinkCheckout
          codeStatus={resultLink.status}
          title={
            resultLink.status == 200 ? "Link generado con éxito." : "Error!"
          }
          message={
            resultLink.status == 200
              ? resultLink.url
              : resultLink.message ?? resultLink.data.error
          }
          idContacto={
            resultLink.data?.idContacto ? resultLink.data.idContacto : null
          }
        />
      )}
    </FormContainer>
  );
};

export default FormularioCompra;
