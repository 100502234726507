import React from 'react'
import {
    ListItem,
    ListIcon,
    Link
} from "@chakra-ui/react"
import { ArrowForwardIcon } from '@chakra-ui/icons'

const Item = ({ link, target, texto }) => {
  return (
    <Link href={link} target={target} w="auto">
        <ListItem w="auto">
            <ListIcon as={ArrowForwardIcon} color='green.500'/>
            {texto}
        </ListItem>
    </Link>
  )
}

export default Item