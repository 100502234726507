import React, { createContext, useEffect, useState } from "react"
import { useToast } from "@chakra-ui/toast";

import {
  clearData,
  firstDayOfTheMonth,
  today,
} from "../Services/UltilsService";
import { getReporteCongelados } from "../Services/ReporteCongeladosService";

export const ResultsCongeladosContext = createContext()

export const ResultsCongeladosContextProvider = ({ children }) => {
  const [formValues, setFormValues] = useState({
    desde: firstDayOfTheMonth,
    hasta: today
  })
  const [loading, setLoading] = useState(false)
  const [reporte, setReporte] = useState([])
  const [reporteFormateado, setReporteFormateado] = useState([])
  const toast = useToast();

  useEffect(() => {
    getReporteFormateado()
  }, [reporte])

  const retrieveDatosCongelados = async () => {
    setLoading(true)

    try {
      const data = await getReporteCongelados(clearData(formValues));
      setReporte(data.data)
    } catch (error) {
      setReporte([])
      toast({
        title: "No se encontraron asignaciones",
        description: error.response.data.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }

    setLoading(false)
  }

  const notifyEditPopup = async (result, editedData, resultMsg = null) => {
    toast({
      title: result === 200 ? "Dato editado!" : resultMsg,
      status: result === 200 ? "success" : "error",
      duration: 5000,
      isClosable: true,
    })

    await retrieveDatosCongelados()
  }

  const getReporteFormateado = () => {
    if (reporte.length !== 0) {
      let datosPorEstado = []

      reporte.estados.map(estado => {
        let datos = reporte.datos.filter(dato => dato.idEstado == estado)

        let datoEstado = {
          [estado]: datos
        }

        datosPorEstado.push(datoEstado)
      })
      setReporteFormateado(datosPorEstado)
    }
  }

  return (
    <ResultsCongeladosContext.Provider value={{
      formValues,
      loading,
      reporte,
      reporteFormateado,
      setFormValues,
      setLoading,
      retrieveDatosCongelados,
      notifyEditPopup
    }}>
      {children}
    </ResultsCongeladosContext.Provider>
  )
}