import React, { useEffect, useState } from "react";
import { Skeleton, Table, Tbody, Flex, Spacer, Box } from "@chakra-ui/react";
import Historia from "./Historia";

import useAxonForm from "../../Hooks/useAxonForm";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import axonApi from "../../Services/AxonApi";
import CargarHistoria from "./CargarHistoria";

const Historial = ({ idContacto, idVenta }) => {
  const [historial, setHistorial] = useState([]);
  const [isLoading, setIsLoading] = useAxonForm();
  const [isLoadingHistoria, setIsLoadingHistoria] = useState(true);

  useEffect(() => {
    axonApi
      .get(
        `/internos/crm/usuarios/contactos/${idContacto}/historia?idVenta=${idVenta}`
      )
      .then(({ data }) => {
        setHistorial(data.historia);
        setIsLoadingHistoria(false);
      });
  }, []);

  return (
    <>
      {historial && (
        <CargarHistoria
          idContacto={idContacto}
          setHistorial={setHistorial}
          historial={historial}
          idVenta={idVenta}
        />
      )}
      <Skeleton isLoaded={!isLoadingHistoria} p={3} pt={0}>
        <Table variant="striped" colorScheme="facebook" mb={10}>
          {historial ? (
            <>
              <Tbody>
                {historial.map((historia) => (
                  <Historia key={historia.id} historia={historia} />
                ))}
              </Tbody>
            </>
          ) : null}
        </Table>
      </Skeleton>
    </>
  );
};

export default Historial;
