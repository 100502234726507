import React, { createContext } from "react";
import useCascada from "../Hooks/useCascada";

export const CascadaContext = createContext();

export const CascadaContextProvider = (props) => {
  const functions = useCascada();

  return (
    <CascadaContext.Provider value={{ ...functions }}>
      {props.children}
    </CascadaContext.Provider>
  );
};
