import axonApi from "./AxonApi";

export const getAsignaciones = async (desde, hasta) => {

    try {
      const url = `/internos/crm/ventas/asignaciones/reportes?desde=${desde}&hasta=${hasta}`;
      const data = await axonApi.get(url)
      return data;
    } catch(e) {
      throw e;
    }
  
};