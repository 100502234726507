import React from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";
const Permisosnt = ({ idPermiso }) => {
  return (
    <Alert status="warning">
      <AlertIcon />
      Necesitas el ID de permiso:
      <strong style={{ marginLeft: "5px" }}>{idPermiso}</strong>
    </Alert>
  );
};

export default Permisosnt;
