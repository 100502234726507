import React, { useState, useContext, useEffect } from "react";

import {Flex, SimpleGrid, Select, Input, Text} from "@chakra-ui/react";

import { cambioMoneda } from "../../Services/PlanCuotasService";

const RowCuota = ({ id, cuota, paises = [], handleChangePlanCuotas, monedaLocal }) => {
    const [cuotaDefault, setCuotaDefault] = useState({...cuota});
    const [isDisabled, setIsDisabled] = useState(cuota.cantidadCuotas != "0" ? false : true)

    const options = [1,2,3,4,5,6,7,8,9,10,11,12];

    const handleChange = (e) => {
        const { name, value } = e.target

        setCuotaDefault({
            ...cuotaDefault,
            [name]: value,
        })

        // Guardo el importe transformado a moneda local
        const cuotaD = cuotaDefault;
        
        cuotaD[name] = value;
        cuotaD['importe'] = cambioMoneda(
            paises,
            cuotaD.importeLocal,
            monedaLocal,
            "ARS"
        )
        
        // Habilito input importe si no seleccionó cantidadCuotas (Esto tmb setea los importes en 0)
        if(name == "cantidadCuotas") {
            if(value == "0") {
                setIsDisabled(true)
                setCuotaDefault({
                    ...cuotaDefault,
                    ["importe"]: 0,
                    ["importeLocal"]: "0",
                })

                cuotaD["importe"] = 0;
                cuotaD["importeLocal"] = "0";
            } else {
                setIsDisabled(false)
            }
        }
        
        // Envio la cuota editada al array de cuotas
        handleChangePlanCuotas(cuotaD, id)
    }

    return (
            <SimpleGrid columns={3} spacing={3}>

                <Flex>
                    <Select
                        onChange={(e) => handleChange(e)}
                        name="cantidadCuotas"
                        value={cuotaDefault.cantidadCuotas ?? "0"} 
                        size="sm" 
                        w="80%" 
                    >   
                        <option value="0">Seleccionar</option>
                        { options.map(option => <option value={option}>{option}</option> )}
                    </Select>
                    <Flex w="20%" alignItems="end" justifyContent="center">
                        <Text fontSize="2xl">x</Text>
                    </Flex>
                </Flex>

                <Input
                    isDisabled={isDisabled}
                    type="number"
                    onChange={(e) => handleChange(e)}
                    name="importeLocal"
                    value={cuotaDefault.importeLocal} 
                    size="sm" 
                />

                <Input
                    onChange={(e) => handleChange(e)}
                    name="importe"
                    value={cambioMoneda(
                        paises,
                        cuotaDefault.importeLocal,
                        monedaLocal,
                        "ARS"
                    )} 
                    size="sm" 
                    isDisabled={true}  
                />

            </SimpleGrid>
    );
}

export default RowCuota;
