import axonApi from "./AxonApi";
import { addQueryParamsToUrl } from "./FormService";
import { clearData } from "./UltilsService";

export const getReporteCongelados = async (values) => {
    let url = "/internos/crm/ventas/reportes/congelados";
    const dataClean = clearData(values);

    url = addQueryParamsToUrl(url, dataClean)

    try {
      const data = await axonApi.get(url)
      return data;
    } catch(e) {
      throw e;
    }
  
};