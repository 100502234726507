import React, { useEffect, useState } from 'react'
import {
    Tabs as ChakraTabs,
    TabList,
    TabPanels,
    TabPanel,
    Box,
} from "@chakra-ui/react";

import TabButton from "../../Tabs/TabButton";
import FiltroCongelados from "./FiltroCongelados"
import ResultsCongelados from "./ResultsCongelados"

import withPermisos from '../../../HOC/withPermisos';

import { UtilDataContextProvider } from '../../../Context/utilDataContext';
import { DatoContextProvider } from '../../../Context/datoContext';
import { ResultsCongeladosContextProvider } from '../../../Context/resultsCongeladosContext';

const ReporteCongelados = () => {
    return (
        <div>
            <ResultsCongeladosContextProvider>
                <UtilDataContextProvider>

                    <ChakraTabs variant="enclosed" colorScheme="blue">
                        <TabList __css={{ borderBottom: "5px solid #1C6EA4" }}>
                            <TabButton title="Listado Salida Congelados" />
                        </TabList>
                
                        <Box height="5px" bg="blue.500" mt={0.5} />
                
                        <TabPanels>
                            <TabPanel bg="#ECF2F7" shadow="lg" borderRadius="lg" borderTop="none">
                                <FiltroCongelados/>
                            </TabPanel>
                        </TabPanels>
                    </ChakraTabs>

                    <DatoContextProvider>
                        <ResultsCongelados/>
                    </DatoContextProvider>

                </UtilDataContextProvider>
            </ResultsCongeladosContextProvider>
        </div>
    )
}

export default withPermisos(ReporteCongelados, 391);