import React, { createContext, useEffect, useState } from "react";

import {
  fetchServiciosByGrupo,
  fetchEstadosVenta,
  fetchOperadoresSupervisor,
  fetchLineas,
  fetchOperadoresBySector,
  fetchOrigenesDato,
  fetchGrupos,
} from "../Services/BuscarDatoService";

import {
  getEnvironmentValue,
  parseArrayToReactSelect,
} from "../Services/UltilsService";
import usePaises from "../Hooks/usePaises";

import usePermisos from "../Hooks/usePermisos";
import { SEARCH_ADMIN } from "../Constants/buscarDato";
export const UtilDataContext = createContext();

export const UtilDataContextProvider = (props) => {
  const { paises } = usePaises(false, true);
  const { hasPermiso } = usePermisos();
  const [coloresEstadosVenta, setColoresEstadosVenta] = useState();
  const [estadosVenta, setEstadosVenta] = useState();
  const [posgrados, setPosgrados] = useState([]);
  const [carreras, setCarreras] = useState([]);
  const [cursosChicos, setCursosChicos] = useState([]);
  const [ebooks, setEbooks] = useState([]);
  const [lineas, setLineas] = useState();
  const [operadores, setOperadores] = useState([]);
  const [origenesDato, setOrigenesDato] = useState([]);
  const [habilitaVentaLaboral, sethabilitaVentaLaboral] = useState(0);
  const [grupos, setGrupos] = useState([]);

  useEffect(() => {
    (async () => {
      setColoresEstadosVenta(await getColoresEstadosVenta());
      setEstadosVenta(await getEstadoVenta());
      setCarreras(await fetchServiciosByGrupo(1));
      setPosgrados(await fetchServiciosByGrupo(5));
      setCursosChicos(await fetchServiciosByGrupo(2));
      setEbooks(await fetchServiciosByGrupo(7));
      setLineas(await getLineas());
      sethabilitaVentaLaboral(await getEnvironment());

      await getOrigenesDato();
      await getGrupos();
    })();
  }, []);

  const getOrigenesDato = async () => {
    const origenes = await fetchOrigenesDato();
    setOrigenesDato(parseArrayToReactSelect(origenes, "id", "codigo"));
  };

  const getGrupos = async () => {
    const grupos = await fetchGrupos();
    setGrupos(parseArrayToReactSelect(grupos, "id", "nombre"));
  };
  const getLineas = async () => {
    const lineas = await fetchLineas();

    return parseArrayToReactSelect(lineas, "id", "descripcion");
  };

  const getEstadoVenta = async () => {
    const data = await fetchEstadosVenta();

    return parseArrayToReactSelect(data, "id", "estado");
  };

  const getColoresEstadosVenta = async () => {
    const data = await fetchEstadosVenta();
    const coloresEstados = [];

    data.forEach((estadoVenta) => {
      const { id, estado, color } = estadoVenta;
      coloresEstados.push({ id, estado, color });
    });

    return coloresEstados;
  };

  const getServiciosByGrupo = (idGrupo) => {
    let array = [];
    if (idGrupo === 1) {
      array = parseArrayToReactSelect(carreras, "id", "codigo");
    }
    if (idGrupo === 5) {
      array = parseArrayToReactSelect(posgrados, "id", "codigo");
    }
    if (idGrupo === 7) {
      array = parseArrayToReactSelect(ebooks, "id", "codigo");
    }

    array.unshift({ value: "", label: "Todos los servicios" });
    return array;
  };

  const getServiciosByIdGrupo = async (idGrupo) => {
    const grupo = parseInt(idGrupo);
    const listadoServicios = await fetchServiciosByGrupo(grupo);
    let array = parseArrayToReactSelect(listadoServicios, "id", "codigo");

    array.unshift({ value: "", label: "Todos los servicios" });
    return array;
  };

  const getAllServices = (showEbooks = false) => {
    let servs = [];
    servs.push(...carreras, ...posgrados, ...cursosChicos);
    if (showEbooks) servs.push(...ebooks);

    return parseArrayToReactSelect(servs, "id", "codigo");
  };

  const setOperadoresBySupervisor = async (supervisor) => {
    const data = await fetchOperadoresSupervisor(supervisor);
    setOperadores(parseArrayToReactSelect(data, "origen", "origen"));

    //Código viejo
    // const isAdmin = hasPermiso(SEARCH_ADMIN);

    // if (isAdmin) {
    //   const data = await fetchOperadoresSupervisor(supervisor);
    //   setOperadores(parseArrayToReactSelect(data, "origen", "origen"));
    // } else {
    //   setOperadores(await getAllOperadores());
    // }
  };

  const filterEstadosVentaVendedor = () => {
    //
    const isAdmin = hasPermiso(SEARCH_ADMIN);
    //
    if (isAdmin) return estadosVenta;

    return estadosVenta.filter(
      (estado) => parseInt(estado.value) !== 1 && parseInt(estado.value) !== 8
    );
  };

  const getAllOperadores = async () => {
    const sectorTres = await fetchOperadoresBySector(3);
    const sectorCuatro = await fetchOperadoresBySector(4);

    const data = sectorTres.concat(sectorCuatro);

    let dataConcatenada = data.map((responsable) => {
      return { value: responsable.login, label: responsable.login };
    });

    const extras = [
      { label: "virgenes", value: "virgenes" },
      { label: "posgrados", value: "posgrados" },
      { label: "api", value: "api" },
    ];

    dataConcatenada = dataConcatenada.concat(extras);

    return dataConcatenada;
  };

  const getEnvironment = async () => {
    try {
      const { data } = await getEnvironmentValue(
        "checkout",
        "habilitacionVentaLaboral"
      );
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UtilDataContext.Provider
      value={{
        getAllServices,
        getServiciosByGrupo,
        estadosVenta,
        coloresEstadosVenta,
        setOperadoresBySupervisor,
        operadores,
        paises,
        filterEstadosVentaVendedor,
        posgrados,
        carreras,
        ebooks,
        cursosChicos,
        lineas,
        origenesDato,
        habilitaVentaLaboral,
        grupos,
        getServiciosByIdGrupo,
      }}
    >
      {props.children}
    </UtilDataContext.Provider>
  );
};
