import React from "react";
import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from "@chakra-ui/react";
import TabButton from "../../Tabs/TabButton";
import FiltroCascada from "./FiltroCascada";
import { CascadaContextProvider } from "../../../Context/cascadaContext";
import ResultsCascada from "./ResultsCascada";
import withPermisos from "../../../HOC/withPermisos";

const ReporteCascada = () => {
  return (
    <CascadaContextProvider>
      <ChakraTabs variant="enclosed" colorScheme="blue">
        <TabList __css={{ borderBottom: "5px solid #1C6EA4" }}>
          <TabButton title="Listado Cascada" />
        </TabList>

        <Box height="5px" bg="blue.500" mt={0.5} />

        <TabPanels>
          <TabPanel bg="#ECF2F7" shadow="lg" borderRadius="lg" borderTop="none">
            <FiltroCascada />
          </TabPanel>
        </TabPanels>
      </ChakraTabs>

      <ResultsCascada />
    </CascadaContextProvider>
  );
};

export default withPermisos(ReporteCascada, 395);
