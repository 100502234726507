import { useContext } from "react"
import {
  Button,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { CascadaContext } from "../../../Context/cascadaContext";

const ModalParaCobrar = ({ onClose, reporte }) => {
  const { formatParaCobrar, getFechaFormateada } = useContext(CascadaContext);
    
  return (
    <ModalContent>
      <ModalHeader>
        Para cobrar {getFechaFormateada(reporte.fecha)}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {formatParaCobrar(reporte.paraCobrar).map((resto) => (
          <p>
            <b>{resto.name}:</b> ${resto.value}
          </p>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cerrar</Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default ModalParaCobrar