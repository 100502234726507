import React from "react";
import { Tab } from "@chakra-ui/react";
const TabButton = ({ title, isDisabled = false }) => {
  const tabsOnFocus = { shadow: false };
  return (
    <Tab
      _focus={tabsOnFocus}
      width="30vw"
      fontWeight="600"
      border="2px solid"
      borderColor="blue.500"
      color="gray.400"
      borderBottom="none"
      bg="gray.300"
      ml="1"
      borderRadius="0px 10px 0px 0px"
      shadow="lg"
      cursor={isDisabled ? "default" : "pointer"}
      isDisabled={isDisabled}
      _selected={{
        color: "blue.500",
        background: "white",
        fontWeight: "650",
      }}
    >
      {title}
    </Tab>
  );
};

export default TabButton;
