import React from "react";

import { Text, Tooltip, useClipboard } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";

const EmailViewer = ({ showEmail, email, isAdmin }) => {

  const { hasCopied, onCopy } = useClipboard(email);
  const toast = useToast();

  const showToast = () => {
    email && toast({
      title: "Email copiado.",
      status: "success",
      duration: 5000,
      isClosable: true,
    })
  }; 
  return (
    <Tooltip label={email} isDisabled={!isAdmin}>
      <Text
        fontSize="md"
        color={showEmail ? "blue.500" : "gray.400"}
        fontWeight={showEmail ? 800 : 500}
        style={showEmail ? { cursor: "pointer" } : null}
        onClick={onCopy}
      >
        {hasCopied && showToast()}
        @
      </Text>
    </Tooltip>
  );
};

export default EmailViewer;
