import React, { useState } from "react";

import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from "@chakra-ui/react";

import TabButton from "../../Tabs/TabButton";

import FiltroAsignaciones from "./FiltroAsignaciones";
import ResultsAsignaciones from "./ResultsAsignaciones";

import { SEARCH_ADMIN } from "../../../Constants/buscarDato";
import withPermisont from "../../../HOC/withPermisont";
const ReporteAsignaciones = () => {
  const [asignaciones, setAsignaciones] = useState([]);

  return (
    <div>
      <ChakraTabs variant="enclosed" colorScheme="blue">
        <TabList __css={{ borderBottom: "5px solid #1C6EA4" }}>
          <TabButton title="Listado Asignaciones" />
        </TabList>

        <Box height="5px" bg="blue.500" mt={0.5} />

        <TabPanels>
          <TabPanel bg="#ECF2F7" shadow="lg" borderRadius="lg" borderTop="none">
            <FiltroAsignaciones setAsignaciones={setAsignaciones} />
          </TabPanel>
        </TabPanels>
      </ChakraTabs>

      <ResultsAsignaciones asignaciones={asignaciones} />
    </div>
  );
};

export default withPermisont(ReporteAsignaciones, SEARCH_ADMIN);
