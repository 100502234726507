import React, { useState, useEffect, createContext } from "react";
import { fetchReporteVentas } from "../Services/ReporteService";
export const ReporteContext = createContext();

export const ReporteContextProvider = (props) => {
  const [results, setResults] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hidePanel, setHidePanel] = useState(true);

  useEffect(() => setHidePanel(results.length === 0), [results]);

  const getReporteVentas = async (params) => {
    setIsFetching(true);
    setResults([]);
    try {
      const res = await fetchReporteVentas(params);
      setResults(res);
    } catch (error) {
      console.log(error);
      setResults([]);
      setIsFetching(false);
      return error;
    }
    setIsFetching(false);
  };

  return (
    <ReporteContext.Provider
      value={{ ...results, isFetching, getReporteVentas, hidePanel }}
    >
      {props.children}
    </ReporteContext.Provider>
  );
};
