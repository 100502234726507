import * as Yup from "yup";

export default Yup.object().shape({
  idGrupo: Yup.string().notRequired("Required"),
  idServicio: Yup.string().notRequired("Required"),
  desde: Yup.string().required("Required"),
  hasta: Yup.string().required("Required"),
  nombre: Yup.string().notRequired("Required"),
  apellido: Yup.string().notRequired("Required"),
  email: Yup.string().email("Email invalido"),
  idPais: Yup.string().notRequired("Required"),
  celular: Yup.string().notRequired("Required"),
});
