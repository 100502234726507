import React, { useState, useContext, useEffect } from "react";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { UtilDataContext } from "../../../Context/utilDataContext";
import { Box, SimpleGrid,HStack } from "@chakra-ui/layout";
import { SessionContext } from "../../../Context/sessionContext";
import { validation } from "./validation";
import { generateLinkLP } from "../../../Services/GeneracionLinkService";
import ResultLinkCheckout from "../ResultLinkCheckout";
import usePermisos from "../../../Hooks/usePermisos";
import { PlanCuotasContextProvider } from "../../../Context/PlanCuotasContext";
import ModalPlanCuotas from "../../PlanCuotas/ModalPlanCuotas";

const FormularioLinkCarrera = ({
  email,
  id: idContacto,
  responsable: operador,
  idVenta,
  pais,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [resultLink, setResultLink] = useState(null);

  const { operadores, setOperadoresBySupervisor } = useContext(UtilDataContext);
  const {
    session: { login },
  } = useContext(SessionContext);
  const { hasPermiso } = usePermisos();

  const initialValues = {
    email: email ?? null,
    operador: login ?? null,
    idContacto: idContacto ?? null,
    idVenta: idVenta ?? null,
  };

  useEffect(() => {
    (async () => {
      await setOperadoresBySupervisor(login);
    })();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const link = await generateLinkLP({ ...formValues });
      setResultLink(link);
    } catch (error) {
      setResultLink(error.response);
    }
    setIsLoading(false);
  };

  const handleChange = ({ values }) =>  {
    setFormValues(values)
  };

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validation}
      handleChange={handleChange}
    >
      <SimpleGrid columns={[1, 2, 3]} spacing={10}>
        {idVenta && (
          <FormField
            name="email"
            label="Email"
            placeholder="Ingrese email"
            labelColor="gray.500"
            bgInput="white"
            type="input"
            disabled={formValues.sinEmail || initialValues.email || !idVenta}
          />
        )}

        <FormField
          isRequired
          disabled={initialValues.operador}
          name="operador"
          label="Responsable"
          placeholder="Seleccione"
          type="react-select"
          labelColor="gray.500"
          bgInput="white"
          options={operadores}
        />
      </SimpleGrid>

      {/* {hasPermiso(400) && (
        <FormField
          isRequired={false}
          name="DA"
          placeholder="Débito automático"
          type="checkbox"
          defaultValue={initialValues.DA}
          labelColor="gray.500"
          bgInput="white"
        />
      )} */}

      <HStack mt={10} spacing={5}>
        <Box>
          <FormField
            name="submit"
            type="submit"
            placeholder="Generar link"
            bgInput="orange.500"
            colorScheme="orange.500"
            size="sm"
            isLoading={isLoading}
          />
        </Box>

        {idVenta && (
          <Box>
            <Box mt={5}></Box>
            <PlanCuotasContextProvider pais={pais} idVenta={idVenta}>
              <ModalPlanCuotas />
            </PlanCuotasContextProvider>
          </Box>
        )}
      </HStack>

      {resultLink && (
        <ResultLinkCheckout
          codeStatus={resultLink.status === 400 ? 400 : 200}
          title={
            resultLink.status === 400
              ? "Error al generar el link."
              : "Link generado con éxito."
          }
          message={
            resultLink.status === 400
              ? "No se pudo generar el link. Revise si el email existe."
              : resultLink
          }
        />
      )}
    </FormContainer>
  );
};

export default FormularioLinkCarrera;
