import React from "react";
import { Flex, Spacer, Box } from "@chakra-ui/react";

import useAxonForm from "../../Hooks/useAxonForm";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import axonApi from "../../Services/AxonApi";
import withPermisos from "../../HOC/withPermisos";

const CargarHistoria = ({ idContacto, historial, setHistorial, idVenta }) => {
    console.log(idVenta);
  const [isLoading, setIsLoading] = useAxonForm();

  const initialValues = { comentario: "" };

  const handleSubmit = (values) => {
    values.idVenta = parseInt(idVenta);
    setIsLoading(true);
    axonApi
      .post(`/internos/crm/usuarios/contactos/${idContacto}/historia`, values)
      .then(({ data }) => {
        setHistorial([data, ...historial]);
        setIsLoading(false);
      });
  };

  const handleChange = (actions) => {
    const { values } = actions;
  };

  return (
    <>
      <FormContainer
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        initialValues={initialValues}
      >
        <Flex p={2} m={1}>
          <FormField
            label="Cargar nueva historia"
            name="comentario"
            type="input"
            labelColor="gray.500"
            bgInput="white"
            placeholder="Cargar Historial"
            isRequired
          />{" "}
          <Spacer />
          <Flex alignItems="end">
            <Box pl={2} pb={1}>
              <FormField
                name="submit"
                type="submit"
                placeholder="Guardar"
                isLoading={isLoading}
                bgInput="orange.500"
                colorScheme="orange.500"
                size="sm"
              />
            </Box>
          </Flex>
        </Flex>
      </FormContainer>
    </>
  );
};

export default withPermisos(CargarHistoria, 383);
