import ModalBajasMes from "./ModalBajasMes";
import ModalParaCobrar from "./ModalParaCobrar";
import { ModalRemanente } from "./ModalRemanente";

export const modalsCascada = [
  {
    name: "paraCobrar",
    component: ModalParaCobrar,
  },
  {
    name: "bajasDelMes",
    component: ModalBajasMes,
  },
  {
    name: "remanente",
    component: ModalRemanente,
  },
];
