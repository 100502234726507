import React, { useContext, useEffect, useReducer, useState } from "react";
import { SessionContext } from "../../Context/sessionContext";

import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";
import { HStack, SimpleGrid, Button, Box } from "@chakra-ui/react";

import {
  firstDayOfTheMonth,
  mainMargin,
  today,
} from "../../Services/UltilsService";
import validation from "./validation";
import grupoServicioValues from "./../../Utils/grupoServicioValues";
import { UtilDataContext } from "../../Context/utilDataContext";
import { ReporteContext } from "../../Context/reporteContext";
import { useToast } from "@chakra-ui/toast";

import useSectoresVenta from "../../Hooks/useSectoresVenta.js";

const FiltroReporte = () => {
  const [servicios, setServicios] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const { addSelectAllExterior } = useSectoresVenta();
  const {
    getServiciosByIdGrupo,
    operadores,
    paises,
    posgrados,
    carreras,
    lineas,
    grupos,
    setOperadoresBySupervisor,
  } = useContext(UtilDataContext);

  const {
    session: { login },
  } = useContext(SessionContext);

  const { isFetching, getReporteVentas } = useContext(ReporteContext);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setServicios(await getServiciosByIdGrupo(formValues.idGrupo));
      setIsLoading(false);
    })();
  }, [formValues.idGrupo, posgrados, carreras]);

  useEffect(() => {
    (async () => {
      await setOperadoresBySupervisor(login);
    })();
  }, []);

  const [keyForm, resetForm] = useReducer((keyform) => {
    return keyform + 1;
  }, 1);

  const handleSubmit = async () => {
    try {
      await getReporteVentas(formValues);
    } catch (error) {
      toast({
        title: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = ({ values }) => setFormValues(values);

  return (
    <div key={keyForm}>
      <FormContainer
        handleSubmit={handleSubmit}
        validationSchema={validation}
        initialValues={{
          desde: firstDayOfTheMonth,
          hasta: today,
        }}
        handleChange={handleChange}
      >
        <SimpleGrid columns={[1, 2, 5]} spacing={mainMargin}>
          <FormField
            name="idGrupo"
            label="Grupo"
            placeholder="Seleccione grupo"
            type="react-select"
            options={grupos}
            labelColor="gray.500"
          />

          <FormField
            name="idServicio"
            label="Servicio"
            placeholder="Seleccione servicio"
            type="react-select"
            disabled={isLoading}
            options={servicios}
            labelColor="gray.500"
          />

          <FormField
            name="idPais"
            label="Pais"
            placeholder="Seleccione pais"
            type="react-select"
            options={addSelectAllExterior(paises)}
            labelColor="gray.500"
          />

          <FormField
            name="operador"
            label="Operador"
            placeholder="Seleccione operador"
            type="react-select"
            options={operadores}
            labelColor="gray.500"
          />

          <FormField
            name="idLinea"
            label="Linea"
            placeholder="Seleccione"
            type="react-select"
            options={lineas}
            labelColor="gray.500"
          />

          <FormField
            bgInput="white"
            name="desde"
            label="Fecha desde"
            placeholder="Seleccione desde"
            type="date"
            labelColor="gray.500"
          />

          <FormField
            bgInput="white"
            name="hasta"
            label="Fecha hasta"
            placeholder="Seleccione hasta"
            type="date"
            labelColor="gray.500"
          />
        </SimpleGrid>

        <HStack mt={mainMargin} spacing={5}>
          <Box>
            <FormField
              name="submit"
              type="submit"
              placeholder="Buscar"
              bgInput="orange.500"
              colorScheme="orange.500"
              labelColor="gray.500"
              size="sm"
              isLoading={isFetching}
            />
          </Box>
        </HStack>
      </FormContainer>
    </div>
  );
};

export default FiltroReporte;
