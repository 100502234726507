import React from "react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import CirculoEstados from "../../Common/CirculoEstados/CirculoEstados";
const CarteraList = (props) => {
  const { data } = props;

  if (!data) return "No se encontro cartera";

  return (
    <Table variant="striped">
      <Tbody>
        {data?.map((row, index) => {
          return (
            <Tr key={index}>
              <Td>{row.operador}</Td>
              <Td>{row.curso}</Td>
              <Td>
                <CirculoEstados
                  background={row.colorEstadoVenta}
                  message={row.estadoVenta}
                  m={0}
                />
              </Td>
              <Td>{row.cantidad}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
export default CarteraList;
