import * as Yup from "yup";
import { today } from "../../Services/UltilsService";

export const validation = Yup.object().shape({
  idServicio: Yup.string().required("Campo requerido."),
  idAnuncio: Yup.string().required("Campo requerido."),
  fechaCarga: Yup.date()
    .max(today, "La fecha no puede ser mayor a hoy.")
    .required("Campo requerido."),
  celular: Yup.string().required("Campo requerido."),
  idPais: Yup.number().required("Campo requerido."),
});
