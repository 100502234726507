import Axios from "axios";

export const submitApiPost = async (url, body = [], handleError = () => {}) => {
  try {
    const res = await Axios.post(url, body);
    return res.data;
  } catch (error) {
    handleError("ALGO SALIO MAL");
  }
};

export const getApi = (url, handleError = () => {}) => {
  const data = Axios.get(url).then((res) => {
    return res.data;
  });

  return data;
};
export const putApi = async (url, body, handleError) => {
  try {
    const res = await Axios.put(url, body);
    return res.data;
  } catch (error) {
    return false;
  }
};
export const getInitialValues = async (url) => {
  try {
    new Error();

    const res = Axios.get(url);
    return res.data;
  } catch (error) {
    //handle error
  }
};

export const addQueryParamsToUrl = (url, values) => {
  const entries = Object.entries(values);

  entries.forEach((entrie) => {
    if (url.indexOf("?") === -1) {
      url = url + "?" + entrie[0] + "=" + entrie[1];
    } else {
      url = url + "&" + entrie[0] + "=" + entrie[1];
    }
  });

  return url;
};

export const addDescripcionToAulas = (arrAulas) => {
  const data = arrAulas.map((aula) => {
    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const fechaFormateada = aula.tiempoInicio.split("-").reverse().join("-");
    const numeroDia = new Date(fechaFormateada).getDay();
    const nombreDia = dias[numeroDia + 1];

    return {
      value: aula.id,
      label:
        aula.alias +
        " " +
        nombreDia +
        " " +
        aula.tiempoInicio +
        " " +
        aula.horaInicio +
        "HS ARG",
    };
  });

  return data;
};
