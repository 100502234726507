import React from "react";
import { HStack, Button } from "@chakra-ui/react";
import ExcelReport from "./ExcelReport";
import { ReporteContext } from "../../Context/reporteContext";
//
const ButtonGruop = React.forwardRef((props, ref) => {
  const { hidePanel } = React.useContext(ReporteContext);

  if (hidePanel) return null;
  return (
    <HStack justify="end" spacing={2} pt={4}>
      <Button
        colorScheme="blue"
        onClick={() => ref.current.scrollIntoView({ behavior: "smooth" })}
        shadow="md"
      >
        Totales
      </Button>
      <ExcelReport />
    </HStack>
  );
});

export default ButtonGruop;
