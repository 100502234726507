import React, { useContext, useEffect } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  AccordionPanel,
  AccordionItem,
  Accordion,
  AccordionButton,
  Heading,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";

import { SessionContext } from "../../../Context/sessionContext";
import { UtilDataContext } from "../../../Context/utilDataContext";
import { ResultsCongeladosContext } from "../../../Context/resultsCongeladosContext";

import DrawerManager from "../../../Common/Drawer/DrawerManager";
import Historial from "../../Historia/Historial";
import ActionButton from "../../../Common/ActionButton/ActionButton";
import EditarDato from "../../EditarDato";

const ResultsCongelados = () => {
  const { session: { login } } = useContext(SessionContext);
  const { setOperadoresBySupervisor } = useContext(UtilDataContext)
  const { 
    reporteFormateado,
    notifyEditPopup
  } = useContext(ResultsCongeladosContext)

  useEffect(() => {
    (async () => {
      await setOperadoresBySupervisor(login)
    })()
  }, [])

  if (!reporteFormateado || reporteFormateado.length == 0) return null;

  return (
    <Accordion allowToggle>
      {reporteFormateado.map((estado, i) => {
        const estadoNombre = estado[Object.keys(estado)[0]][0].estado;
        const datosEstado = estado[Object.keys(estado)[0]];
        const datosTotal = estado[Object.keys(estado)[0]].length;

        return (
          <AccordionItem>
            <Box
              key={i}
              borderRadius="lg"
              bgColor="white"
              shadow="lg"
              mt="2vh !important"
              width="auto"
            >
              <AccordionButton>
                <Heading pt={1} pb={1} as="h3" size="sm">
                  {estadoNombre} - Total: {datosTotal}
                </Heading>
              </AccordionButton>
              <Box overflowX="auto">
                <AccordionPanel p={4}>
                  <Table variant="striped" colorScheme="gray" size="sm">
                    <Thead>
                      <Tr>
                        <Th textAlign="center">#</Th>
                        <Th textAlign="center">ID Venta</Th>
                        <Th textAlign="center">ID Contacto</Th>
                        <Th textAlign="center">Nombre y Apellido</Th>
                        <Th textAlign="center">Fecha creación</Th>
                        <Th textAlign="center">Fecha cambio estado</Th>
                        <Th textAlign="center">Anuncio</Th>
                        <Th textAlign="center">Responsable Actual</Th>
                        <Th textAlign="center">Historia</Th>
                        <Th textAlign="center">Editar Dato</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {datosEstado.map((dato, key) => {
                        return (
                          <Tr key={dato.id}>
                            <Td textAlign="center">{key + 1}</Td>
                            <Td textAlign="center">{dato.idVenta}</Td>
                            <Td textAlign="center">{dato.idContacto}</Td>
                            <Td textAlign="center">{`${dato.nombre} ${dato.apellido}`}</Td>
                            <Td textAlign="center">{dato.fechainicio}</Td>
                            <Td textAlign="center">{dato.fechaHora}</Td>
                            <Td textAlign="center">{dato.anuncio}</Td>
                            <Td textAlign="center">{dato.responsable}</Td>
                            <Td textAlign="center">
                              <DrawerManager
                                trigger={
                                  <ActionButton
                                    iconComponent={
                                      <MdSearch size={25} color="#206cc6" />
                                    }
                                  />
                                }
                                size="lg"
                                title="Historia"
                                component={
                                  <Historial idContacto={dato.idContacto} idVenta={dato.idVenta} />
                                }
                              />
                            </Td>
                            <Td textAlign="center">
                              <EditarDato 
                                data={{
                                  ...dato, 
                                  idEstadoVenta: dato.idEstado,
                                  pais: {
                                    id: dato.pais
                                  }
                                }}
                                index={key}
                                editResult={notifyEditPopup}
                                isDisabled={false}
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </AccordionPanel>
              </Box>
            </Box>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default ResultsCongelados;
