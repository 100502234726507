export const cambioMoneda = (paises, importe, monedaOrigen, monedaFinal) => {
  if (paises.length <= 0 || !monedaOrigen) return 0;

  const paisFinal = paises.find((pais) => pais.moneda == monedaFinal);
  const paisOrigen = paises.find((pais) => pais.moneda == monedaOrigen);

  if (monedaFinal == "ARS") {
    return Math.round(importe * paisOrigen.coefTipoCambio);
  } else {
    return Math.round(importe / paisFinal.coefTipoCambio);
  }
};

export const getAcuerdo = (cuotas, paises, monedaLocal) => {
  let acuerdo = 0;

  cuotas.map((cuota) => {
    if (cuota.importeLocal) {
      acuerdo += parseInt(cuota.importeLocal) * parseInt(cuota.cantidadCuotas);
    }
  });

  acuerdo = cambioMoneda(paises, acuerdo, monedaLocal, "ARS");

  return acuerdo;
};

export const validarDatosCuotas = (cuotas) => {
  let cuotasLimpias = cuotas;

  cuotasLimpias.map((cuota, index) => {
    if (
      cuota.cantidadCuotas == "0" ||
      cuota.importeLocal == "0" ||
      cuota.importe == "0"
    ) {
      let orden = cuota.orden;
      cuotasLimpias = cuotasLimpias.filter(cuota => cuota.orden !== orden)
    }
  });

  return cuotasLimpias;
};

export const validarCantidadCuotas = (cuotas) => {
  let cont = 0;
  cuotas.map((cuota) => (cont += parseInt(cuota.cantidadCuotas)));
  return cont > 12 ? false : true;
};

export const formateoCuotas = (planDeCuotas = [], paises, monedaLocal, maxRows) => {
  let cuotasFormateadas = planDeCuotas;

  for (let i = 0; i < maxRows; i++) {
    let cuota = planDeCuotas[i] ? planDeCuotas[i] : false;

    cuotasFormateadas[i] = cuota
      ? cuota
      : {
          cantidadCuotas: "0",
          importeLocal: "0",
          importe: "0",
          orden: String(i + 1),
        };

    cuotasFormateadas[i].importe = cambioMoneda(
      paises,
      cuota.importeLocal,
      monedaLocal,
      "ARS"
    );
  }

  return cuotasFormateadas;
};
