import React, { createContext } from "react";

import useCheckRow from "../Hooks/useCheckRow";

export const ResultsContext = createContext();

export const ResultsContexttProvider = (props) => {
  const { checkedRows, dispatchCheck, counter } = useCheckRow();

  return (
    <ResultsContext.Provider value={{ checkedRows, dispatchCheck, counter }}>
      {props.children}
    </ResultsContext.Provider>
  );
};
