import React, { useContext } from "react";
import { SessionContext } from "../Context/sessionContext";
import Permisosnt from "../Utils/Permisosnt";
const usePermisos = () => {
  const { permisos } = useContext(SessionContext);

  const hasPermiso = (nroPermiso) => {
    return !!permisos[nroPermiso];
  };

  const getDisplayPermisont = (nroPermiso) => (
    <Permisosnt idPermiso={nroPermiso} />
  );

  return { hasPermiso, getDisplayPermisont };
};

export default usePermisos;
