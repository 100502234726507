import React from "react";
import { List, Heading, Box } from "@chakra-ui/react";

import withPermisos from "../../HOC/withPermisos";
import { data } from "./data";
import Item from "./Item";

const Util = () => {
  return (
    <Box ml={5}>
      <Heading mb={3}>Enlaces utiles</Heading>
      <List w="40%" spacing={3}>
        {data && data.map((item) => <Item {...item} />)}
      </List>
    </Box>
  );
};

export default withPermisos(Util, 401);
