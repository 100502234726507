import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  HStack,
  Button,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { CascadaContext } from "../../../Context/cascadaContext";
import Reporte from "./Reporte";

const ResultsCascada = () => {
  const endOfPageRef = React.useRef(null);
  const topOfPageRef = React.useRef(null);
  const { reportes, loading } = useContext(CascadaContext);
  return (
    reportes.length > 0 && !loading && (
      <>
        <div ref={topOfPageRef}>
          <HStack p={3} justify="end">
            <Button
              colorScheme="blue"
              onClick={() =>
                endOfPageRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Abajo
            </Button>
          </HStack>
        </div>
        <Box
          borderRadius="lg"
          bgColor="white"
          shadow="lg"
          mt="2vh !important"
          width="auto"
        >
          <Table colorScheme="gray" size="md">
            <Thead>
              <Tr>
                <Th textAlign="center">Año - Mes</Th>
                <Th textAlign="center">Ventas Nuevas</Th>
                <Th textAlign="center">Cobrado de Cascada</Th>
                <Th textAlign="center">Total Remanente</Th>
                <Th textAlign="center">Cobrado del Mes</Th>
                <Th textAlign="center">Recupero</Th>
                <Th textAlign="center">Bajas del mes</Th>
                <Th textAlign="center">Para cobrar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reportes.map((reporte, key) => (
                <Reporte key={key} reporte={reporte} />
              ))}
            </Tbody>
          </Table>
        </Box>
        <div ref={endOfPageRef}>
          <HStack p={3} justify="end">
            <Button
              colorScheme="blue"
              onClick={() =>
                topOfPageRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Arriba
            </Button>
          </HStack>
        </div>
      </>
    )
  );
};

export default ResultsCascada;
