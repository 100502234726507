import React from "react";
import { ModalAxon } from "@axontraining-sistemas/lib-common";
import CarteraList from "./CarteraList";
import Trigger from "./Trigger";
import { DatoContext } from "../../Context/datoContext";

const Index = () => {
  const { datos, fetchIsLoading } = React.useContext(DatoContext);

  //Aca evaluo todas las poisibilidades que hay para que no se muestre el boton
  const dontShowCartera =
    fetchIsLoading || !datos || datos?.cartera?.length === 0;

  if (dontShowCartera) return null;

  return (
    <>
      <ModalAxon
        isDisabled={!datos.cartera}
        title="Cartera"
        size="4xl"
        trigger={<Trigger showCartera={!!datos.cartera} />}
        render={() => <CarteraList data={datos.cartera} />}
      />
    </>
  );
};

export default Index;
