import axonApi from "./AxonApi";
import { addQueryParamsToUrl } from "./FormService";
import { clearData } from "./UltilsService";

export const getReporteCascada = async (values, pais) => {
  delete values.hasta;
  let url = "/internos/crm/ventas/reportes/cascada";
  const dataClean = clearData({...values, ...pais});

  url = addQueryParamsToUrl(url, dataClean);

  const data = await axonApi.get(url);
  return data;
};
