import React from "react";
import { Button } from "@chakra-ui/react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ReporteContext } from "../../Context/reporteContext";

const ExcelReport = () => {
  const { rows, totales } = React.useContext(ReporteContext);

  const parsedTotales = totales
  ?.map((total) => ({
    [total.label.replace(/ /g, "")]: total.value,
  }))
  ?.reduce((prevValue, currentValue) => ({
    ...currentValue,
    ...prevValue,
  }));

  const handleExport = () => {
    const wb = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(rows, {
      header: [
        "fechaIngreso",
        "fechaVence",
        "operador",
        "nombreAlumno",
        "idUsuario",
        "acuerdo",
        "importeCobrado",
        "cantCuotasCli",
        "objMensual",
        "anuncio",
        "idServicio",
        "pais",
        "descripcionEstado",
        "importeUsd",
      ],
    });
    XLSX.utils.book_append_sheet(wb, ws1, "Listado Facturas");

    const ws2 = XLSX.utils.json_to_sheet([parsedTotales], {
      header: [
        "SumaCuotas",
        "CantidadSeñas",
        "Acuerdos",
        "DiasTrabajados",
        "PromedioxDía",
        "VentaNueva",
      ],
    });
    XLSX.utils.book_append_sheet(wb, ws2, "totales");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Reporte_de_venta.xlsx");
  };

  return (
    <Button colorScheme="green" shadow="md" onClick={handleExport}>
      Exportar Excel
    </Button>
  );
};

export default ExcelReport;
