import React, { useContext, useState } from "react";
import { SimpleGrid, Box, HStack, Text, CloseButton } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/toast";
import { FormContainer, FormField } from "@axontraining-sistemas/lib-common";

import { clearData, generateLog, today } from "../../Services/UltilsService";
import { postCargaDato, postContactoTracking } from "../../Services/CargaDatoService";
import { putDato } from "../../Services/EditDataService";
import { SessionContext } from "../../Context/sessionContext";
import { UtilDataContext } from "../../Context/utilDataContext";
import { SHOW_OPERADORES } from "../../Constants/buscarDato";

import { validation } from "./validation";
import usePermisos from "../../Hooks/usePermisos";

const Index = React.memo(
  ({ paises, getAllServices, origenesDato, pushNewDato }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { hasPermiso } = usePermisos();
    const { operadores } = useContext(UtilDataContext);
    const {
      session: { login },
    } = useContext(SessionContext);

    const operador = hasPermiso(SHOW_OPERADORES);
    const toast = useToast();

    const initialValues = {
      fechaCarga: today,
    };

    const handleSubmit = async (values) => {
      setIsLoading(true);
      const data = clearData(values);
      data.idServicio = parseInt(data.idServicio);
      data.usuarioResponsable = login;

      try {
        const res = await postCargaDato(data);

        toast({
          title: "Dato cargado!",
          description: res.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        generateLog("log_crm_estados", {
          idEstado: 1,
          vendedor: login,
          idVenta: res.idVenta,
          idServicio: data.idServicio,
        });

        await pushNewDato(res.idVenta, data.fechaCarga, true);

        await postContactoTracking(res.idContacto)
      } catch (e) {
        if (e.asignar) {
          toast({
            position: "bottom",
            render: () => (
              <Box
                color="white"
                p={3}
                bg="red.500"
                style={{ borderRadius: "5px" }}
              >
                <HStack
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <WarningIcon style={{ marginRight: "20px" }} />
                  <Text style={{ fontWeight: "bold" }}>Error</Text>
                  <CloseButton onClick={() => toast.closeAll()} />
                </HStack>
                <Text style={{ textAlign: "center" }}>{e.message}</Text>
                <Text
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "10px 0",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    asignarResponsable(e.idVenta, data.fechaCarga);
                  }}
                >
                  Asignarmelo
                </Text>
              </Box>
            ),
            duration: null,
          });
        } else {
          toast({
            title: "Error al cargar dato!",
            description: e.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      setIsLoading(false);
    };

    const asignarResponsable = async (idVenta, fechaCarga) => {
      toast.closeAll();
      let data = {
        actualizarResponsable: true,
      };
      try {
        const res = await putDato(data, idVenta, today);

        await pushNewDato(idVenta, today);

        toast({
          title: "Dato cargado!",
          description: res.data,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error al cargar dato!",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    return (
      //Alambre cosmico, cuando cambia isLoading se resetea el componente.
      <div key={isLoading}>
        <FormContainer
          handleSubmit={handleSubmit}
          validationSchema={validation}
          initialValues={initialValues}
          handleChange={() => {}}
        >
          <SimpleGrid columns={[1, 2, 4]} spacing={2}>
            <FormField
              isRequired
              name="idServicio"
              label="Servicio"
              placeholder="Seleccione servicio"
              type="react-select"
              labelColor="gray.500"
              bgInput="white"
              options={getAllServices()}
            />
            <FormField
              isRequired
              name="idAnuncio"
              label="Origen de datos"
              placeholder="Seleccione origen"
              type="react-select"
              labelColor="gray.500"
              bgInput="white"
              options={origenesDato}
            />
            <FormField
              isRequired
              name="fechaCarga"
              label="Fecha"
              placeholder="Seleccione fecha"
              labelColor="gray.500"
              bgInput="white"
              type="date"
            />
            <FormField
              name="nombre"
              label="Nombre"
              placeholder="Ingrese nombre"
              labelColor="gray.500"
              bgInput="white"
              type="input"
            />
            <FormField
              name="apellido"
              label="Apellido"
              placeholder="Ingrese apellido"
              labelColor="gray.500"
              bgInput="white"
              type="input"
            />

            <FormField
              isRequired
              name="idPais"
              label="Pais"
              placeholder="Seleccione pais"
              type="react-select"
              labelColor="gray.500"
              bgInput="white"
              options={paises}
            />

            <FormField
              isRequired
              name="celular"
              label="Celular"
              placeholder="Ingrese celular"
              labelColor="gray.500"
              bgInput="white"
              type="input"
            />

            <FormField
              name="email"
              label="Email"
              placeholder="Ingrese email"
              labelColor="gray.500"
              bgInput="white"
              type="input"
            />
            <FormField
              name="consulta"
              label="Observaciones"
              placeholder="Ingrese observaciones"
              labelColor="gray.500"
              bgInput="white"
              type="input"
            />

            {operador && (
              <FormField
                name="operador"
                label="Operador"
                placeholder="Seleccione operador"
                type="react-select"
                options={operadores}
                labelColor="gray.500"
              />
            )}
          </SimpleGrid>

          <Box mt={5}>
            <FormField
              name="submit"
              type="submit"
              placeholder="Guardar"
              bgInput="orange.500"
              colorScheme="orange.500"
              size="sm"
              isLoading={isLoading}
            />
          </Box>
        </FormContainer>
      </div>
    );
  }
);

export default Index;
