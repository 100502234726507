import { useContext } from "react";
import {
  Button,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { CascadaContext } from "../../../Context/cascadaContext";

export const ModalRemanente = ({ onClose, reporte }) => {
  const { getMesAnterior, formatNumber } = useContext(CascadaContext);
  return (
    <ModalContent>
      <ModalHeader>Remanentes de {getMesAnterior(reporte.fecha)}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <p>
          <b>Total Remenente</b>: ${formatNumber(reporte.detalleRemanente.total)}
        </p>
        <p>
          <b>Remenente Pagado</b>: ${formatNumber(reporte.detalleRemanente.pagado)}
        </p>
        <p>
          <b>Remenente Baja</b>: ${formatNumber(reporte.detalleRemanente.baja)}
        </p>
        <p>
          <b>Remenente Pendiente</b>: ${formatNumber(reporte.detalleRemanente.pendiente)}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cerrar</Button>
      </ModalFooter>
    </ModalContent>
  );
};
