import axios from "axios";
import axonApi from "./AxonApi";

export const fetchCursosByGrupo = async (grupo) => {
  const url = `https://api.axontraining.com/academico/grupos/${grupo}/servicios`;

  try {
    const res = await axios.get(url);
    return res.data;
  } catch (e) {
    return e;
  }
};

export const postCargaDato = async (data) => {
  const url = `/internos/crm/ventas/dato`;

  try {
    const res = await axonApi.post(url, data);
    return res.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const postContactoTracking = async (idContacto) => {
  // const url = `http://localhost:8080/add-contacto-tracking`
  const url = `/usuarios/contactos/add-tracking`;
  const contactoTrackingBody = {
    idContacto,
    tag: "crmCargarDato",
    urlSource: "crm.axontraining.com/dato",
  };

  try {
    const res = await axonApi.post(url, contactoTrackingBody);
    return res.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const setArrayForSelect = (datos, value, label) => {
  // label viene un array, este alambrito hay que refactorizar, para hacerlo generico.
  const options = datos.map((dato) => {
    return {
      label: dato[label[0]] + " - " + dato[label[1]],
      value: dato[value],
    };
  });
  return options;
};

export const postCargaDatoDiario = async (data) => {
  const url = `/internos/crm/datos-diarios`;

  try {
    const res = await axonApi.post(url, data);
    return res.data;
  } catch (e) {
    throw e.response.data.error;
  }
};

export const updateStateContext = (dataActualizada, setDatos, datos, fecha) => {
  const stats = [
    { label: "entrantes", value: dataActualizada.entrantes },
    { label: "nuevos", value: dataActualizada.nuevos },
    { label: "alumnos", value: dataActualizada.alumnos },
    { label: "ventas", value: dataActualizada.ventas },
  ];

  // Actualiza la data del context
  const datita = datos;
  datita.data[fecha].stats = stats;
  setDatos(datita);
};

export const postAsignacionMultiple = async (data) => {
  const url = `/internos/crm/ventas/asignaciones`;

  try {
    const res = await axonApi.post(url, data);
    return res.data;
  } catch (e) {
    throw e.response.data.error;
  }
};

export const validateCuotasPagas = async (idVenta) => {
  const url = `/ventas/${idVenta}/primera-etapa/cuotas`;

  try {
    const res = await axonApi.get(url);
  } catch (error) {
    throw error.response.data.error;
  }
};
