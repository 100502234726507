import { normalize, schema } from "normalizr";
import Axios from "axios";

const normaliarJsonPermisos = (array) => {
  const permiso = new schema.Entity(
    "permisos",
    {},
    { idAttribute: "idaction" }
  );
  return normalize(array, [permiso]).entities.permisos;
};

export const getPermisosNormalizados = async (userId) => {
  if (userId) {
    const res = await Axios.get(
      `https://api.axontraining.com/usuarios/${userId}/acciones`
    );

    return normaliarJsonPermisos(res.data);
  }
  return {};
};
