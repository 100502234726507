import React, { useState } from "react";
import { getTabsLinks, tabsLinks } from "../../Utils/tabGeneracionLinks";
import { Tabs as ChakraTabs, TabList, Box } from "@chakra-ui/react";
import TabButton from "../Tabs/TabButton";
import { UtilDataContextProvider } from "../../Context/utilDataContext";
import useQueryParam from "../../Hooks/useQueryParam";

const GeneracionLink = () => {
  const [typeLink, setTypeLink] = useState(0);
  const queries = useQueryParam();

  const paramsObject = queries.params
    ? JSON.parse(decodeURIComponent(escape(window.atob(queries.params))))
    : null;

  const Component = tabsLinks[typeLink].component;

  return (
    <>
      <ChakraTabs variant="enclosed" colorScheme="blue">
        <TabList __css={{ borderBottom: "5px solid #1C6EA4" }}>
          {tabsLinks.map((tab, index) => {
            return (
              <div key={index} onClick={() => setTypeLink(index)}>
                <TabButton
                  isDisabled={
                    index === 2 &&
                    paramsObject &&
                    !(
                      paramsObject.idServicio == 20 ||
                      paramsObject.idServicio == 86
                    )
                  }
                  title={tab.name}
                />
              </div>
            );
          })}
        </TabList>

        <Box height="5px" bg="blue.500" mt={0.5} />

        <Box bg="#ECF2F7" shadow="lg" borderRadius="lg" borderTop="none" p={3}>
          <UtilDataContextProvider>
            <Component {...paramsObject} />
          </UtilDataContextProvider>
        </Box>
      </ChakraTabs>
    </>
  );
};

export default GeneracionLink;
