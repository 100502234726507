import React, { useState, useContext } from "react";
import {
  Tr,
  Td,
  Modal,
  useDisclosure,
  Tooltip,
  ModalOverlay,
} from "@chakra-ui/react";
import { firstDayOfTheMonth } from "../../../Services/UltilsService";
import { CascadaContext } from "../../../Context/cascadaContext";

import { modalsCascada } from "../ReporteModals/modalsCascada";

const Reporte = ({ reporte }) => {
  const [modalForColumn, setModalForColumn] = useState("paraCobrar");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formatNumber, getFechaFormateada } = useContext(CascadaContext);

  const Component =
    modalsCascada[
      modalsCascada.findIndex((modal) => modal.name === modalForColumn)
    ].component;

  const handleModal = (name) => {
    setModalForColumn(name);
    onOpen();
  };

  return (
    <>
      <Tr
        bg={reporte.fecha === firstDayOfTheMonth ? "blue.500" : ""}
        color={reporte.fecha === firstDayOfTheMonth ? "white" : ""}
      >
        <Td textAlign={"center"}>{getFechaFormateada(reporte.fecha)}</Td>
        <Td textAlign={"center"}>${formatNumber(reporte.ventasNuevas)}</Td>
        <Td textAlign={"center"}>
          ${formatNumber(reporte.cobradoCascada)} ({reporte.porcentajeCascada}%)
        </Td>

        <Tooltip label="Ver más detalle remanente del mes anterior" placement="top">
          <Td
            textAlign={"center"}
            cursor={"pointer"}
            onClick={() => handleModal("remanente")}
          >
            ${formatNumber(reporte.remanente)}
          </Td>
        </Tooltip>

        <Td textAlign={"center"}>${formatNumber(reporte.cobradoMes)}</Td>
        <Td textAlign={"center"}>${formatNumber(reporte.recupero)}</Td>

        <Tooltip label="Ver más detalle bajas del mes" placement="top">
          <Td
            textAlign={"center"}
            cursor={"pointer"}
            onClick={() => handleModal("bajasDelMes")}
          >
            ${formatNumber(reporte.bajas)}
          </Td>
        </Tooltip>

        <Tooltip label="Ver más detalle a cobrar" placement="top">
          <Td
            textAlign={"center"}
            cursor={"pointer"}
            onClick={() => handleModal("paraCobrar")}
          >
            ${formatNumber(reporte.paraCobrar.Total)}
          </Td>
        </Tooltip>
      </Tr>

      {/* Modal para columna de acciones */}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Component onClose={onClose} reporte={reporte} />
      </Modal>
    </>
  );
};

export default Reporte;
