import React, { useState, useRef } from "react";
import { Tr, Td } from "@chakra-ui/react";
import { FaPencilAlt } from "react-icons/fa";
import axonApi from "../../Services/AxonApi";
import { useToast } from "@chakra-ui/react";

const CampaniaEditable = ({ data }) => {
  const [isEditingCampania, setIsEditingCampania] = useState(false);
  const [initialValue, setInitialValue] = useState(data.valor);
  const [valor, setValor] = useState(data.valor);
  const toast = useToast();
  const ref = useRef(null);

  const centerTextStyle = {
    textAlign: "center",
    color: "#4A5568",
    fontWeight: 700,
    width: 250,
  };

  const handlePrecio = () => {
    const body = {
      idCampania: data.id,
      valor: valor,
    };
    axonApi.post("/internos/crm/campania", body).then((res) => {
      if (res.data.message === "Success") {
        setInitialValue(valor);
        toast({
          title: "Valor actualizado.",
          description: "Se actualizó el valor correctamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setMonto(data.valor);
        toast({
          title: "Error.",
          description: "No se pudo actualizar el valor.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <>
      <Tr>
        <Td style={centerTextStyle}>{data.nombreAmigable}</Td>
        {isEditingCampania ? (
          <Td style={centerTextStyle}>
            <input
              ref={ref}
              style={{ width: "50px" }}
              value={valor}
              type="number"
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  setIsEditingCampania(false);
                  if (
                    e.target.value != initialValue &&
                    e.target.value &&
                    e.target.value !== ""
                  ) {
                    handlePrecio();
                  } else if (e.target.value && e.target.value !== "") {
                    setValor(initialValue);
                  } else {
                    setValor(initialValue);
                    toast({
                      title: "Error.",
                      description: "No ingresó ningún valor.",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }
              }}
              onBlur={(e) => {
                setIsEditingCampania(false);
                if (
                  e.target.value != initialValue &&
                  e.target.value &&
                  e.target.value !== ""
                ) {
                  handlePrecio();
                } else if (e.target.value && e.target.value !== "") {
                  setValor(initialValue);
                } else {
                  setValor(initialValue);
                  toast({
                    title: "Error.",
                    description: "No ingresó ningún valor.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
              onChange={(e) => {
                setValor(e.target.value);
              }}
            />
          </Td>
        ) : (
          <Td style={centerTextStyle}>
            <span>${valor}</span>
          </Td>
        )}
        <Td>
          <FaPencilAlt
            onClick={() => {
              setIsEditingCampania(true);
              setTimeout(() => {
                ref.current?.focus();
              }, 1);
            }}
            style={{ marginLeft: "22px", cursor: "pointer" }}
            size={20}
            color="#FF7000"
          />
        </Td>
      </Tr>
    </>
  );
};

export default CampaniaEditable;
