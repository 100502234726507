import axonApi from "./AxonApi";

export const getFullData = async (selectedOperador, carteraCompleta) => {
  axonApi
  const { data } = await axonApi.get(
    `/internos/cobranzas/lotes?operador=${selectedOperador}&carteraCompleta=${carteraCompleta}`
  );
  return data;
};

export const getOperadores = async () => {
  const { data } = await axonApi.get(
    "/internos/cobranzas/lotes/operadores"
  );

  return data;
};

export const getColorToque = (cntToque) => {
  const colores = {
    0: "gray",
    1: "green",
    2: "orange",
  };
  return colores.hasOwnProperty(cntToque) ? colores[cntToque] : "red";
};

export const ordenar = (object, key, sortType) => {
  if (sortType) {
    return object.sort((a, b) =>
      a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1
    );
  }

  return object.sort((a, b) =>
    a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1
  );
};

export const setArrayForSelect = (datos, value, label) => {
  const options = datos.map((dato) => {
    return { label: dato[label], value: dato[value] };
  });
  return options;
};

export const cambiarOperadores = async (obj) => {
  const res = await axonApi.post(
    "/internos/cobranzas/lotes/operadores",
    obj
  );
  return res;
};

export const resetOperadores = async () => {
  const res = await axonApi.get(
    "/internos/cobranzas/lotes/operadores/reset"
  );

  return res;
};

export const getFullDataRecupero = async (operador, carteraCompleta) => {
  const { data } = await axonApi.get(
    `/internos/cobranzas/lotes/recupero?operador=${operador}&carteraCompleta=${carteraCompleta}`
  );
  return data;
};
export const resetOperadoresRecupero = async () => {
  const res = await axonApi.get(
    "/internos/cobranzas/lotes/operadores/recupero/reset"
  );

  return res;
};

export const cambiarOperadoresRecupero = async (obj) => {
  const res = await axonApi.post(
    "/internos/cobranzas/lotes/operadores/recupero",
    obj
  );

  return res;
};
